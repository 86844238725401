const WebHero = () => {
  const images = ['/assets/images/service_page/web/hero_section/web_1.svg', '/assets/images/service_page/web/hero_section/web_2.svg', '/assets/images/service_page/web/hero_section/web_3.svg'];

  return (
    <section className="mt-20 overflow-x-hidden px-5 pb-[75px] lg:pb-[100px]">
      <div className="flex flex-col items-center justify-center">
        <h1 className="kanit-bold mt-10 bg-gradient-to-b from-white to-white/20 bg-clip-text text-center text-3xl capitalize leading-[150%] text-transparent md:text-5xl lg:text-[62px]">
          <span className="mb-6 inline lg:mb-8 lg:block">Digitize your company</span>
          <span className="mx-2 md:mx-3 lg:mx-4">with Exenesia to Go digital Era</span>
        </h1>
      </div>

      <div className="relative mt-[90px] px-10 md:px-[50px] lg:mb-32 lg:px-[100px]">
        <div className="flex items-center justify-center">
          <img
            src={images[0]}
            alt="web_preview"
            loading="lazy"
            className="h-[255px] w-[525px] object-cover object-center drop-shadow-xl lg:z-10 lg:h-[288px] lg:w-[600px] lg:shadow-2xl lg:shadow-[#F14BFF]/50"
          />
        </div>

        <div className="mt-5 flex flex-col items-center justify-center gap-5 lg:absolute lg:left-[50%] lg:top-32 lg:mt-0 lg:-translate-x-[50%] lg:flex-row">
          <img src={images[1]} alt="web_preview" loading="lazy" className="h-[255px] w-[525px] object-cover object-center drop-shadow-xl" />
          <img src={images[2]} alt="web_preview" loading="lazy" className="h-[255px] w-[525px] object-cover object-center" />
        </div>
      </div>
    </section>
  );
};

export default WebHero;
