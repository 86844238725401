import { useCallback, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Swiper, SwiperSlide } from 'swiper/react';

const OurPortfolio = () => {
  const [currentTab, setCurrentTab] = useState('Mobile App');

  const [descriptionDisplay, setDescriptionDisplay] = useState({ title: '', desc: '', portolio: [] });

  const [swiperIndex, setSwiperIndex] = useState(0);

  const swiperRef = useRef(null);

  const tabs = [
    {
      id: 'Mobile App',
      title: 'Mobile Apps Development',
      desc: 'Mobile apps are a form of interaction between a business and your clients to provide proof of a service, together with us build your business idea and create customer friendly in your business line',
    },
    {
      id: 'Web App',
      title: 'Web Apps Development',
      desc: 'Web apps are a form of interaction between a business and your clients to provide proof of a service, together with us build your business idea and create customer friendly in your business line',
    },
    {
      id: 'Marketing Digital',
      title: 'Marketing Digital',
      desc: 'Marketing Digital are a form of interaction between a business and your clients to provide proof of a service, together with us build your business idea and create customer friendly in your business line',
    },
  ];

  const projectList = [
    {
      name: 'Kartu AK-1 Digital Konut',
      category: 'Mobile App',
      url: 'kartu-ak-1-digital-konut',
      imageUrl: 'assets/images/home_page/portfolio_section/project_1.png',
    },
    {
      name: 'Silancar Konut',
      url: 'silancar-konut',
      category: 'Mobile App',
      imageUrl: 'assets/images/home_page/portfolio_section/project_2.png',
    },
    {
      name: 'Asistenku Apps',
      category: 'Mobile App',
      url: 'asistenku',
      imageUrl: 'assets/images/home_page/portfolio_section/project_3.png',
    },
    {
      name: 'KPI System Apps',
      category: 'Mobile App',
      url: 'hc-perfomance',
      imageUrl: 'assets/images/home_page/portfolio_section/project_4.png',
    },
    {
      name: 'Bionmed Apps',
      category: 'Mobile App',
      url: 'bonmed-apps',
      imageUrl: 'assets/images/home_page/portfolio_section/project_5.png',
    },
    {
      name: 'Eksam Apps',
      category: 'Mobile App',
      url: 'eksam-apps',
      imageUrl: 'assets/images/home_page/portfolio_section/project_6.png',
    },
    {
      name: 'Kean Signature',
      category: 'Web App',
      url: 'kea-signature',
      imageUrl: 'assets/images/home_page/portfolio_section/project_7.png',
    },
    {
      name: 'Nakertrans Konawe Utara',
      category: 'Web App',
      url: 'naketrans-konawe-utara',
      imageUrl: 'assets/images/home_page/portfolio_section/project_8.png',
    },
    {
      name: 'DLH Konawe Utara',
      category: 'Web App',
      url: 'naketrans-konawe-utara-dlh',
      imageUrl: 'assets/images/home_page/portfolio_section/project_9.png',
    },
    {
      name: 'Kartu AK-1 Web app',
      category: 'Web App',
      url: 'kartu-ak-1-digital-konut',
      imageUrl: 'assets/images/home_page/portfolio_section/project_10.png',
    },
    {
      name: 'Silancar Web App',
      category: 'Web App',
      url: 'silancar-konut',
      imageUrl: 'assets/images/home_page/portfolio_section/project_11.png',
    },
    {
      name: 'Halalin Web App',
      category: 'Web App',
      url: 'halal-in',
      imageUrl: 'assets/images/home_page/portfolio_section/project_12.png',
    },
    {
      name: 'Aerofood (procurement)',
      category: 'Web App',
      url: 'aerofood-acs',
      imageUrl: 'assets/images/home_page/portfolio_section/project_13.png',
    },
    {
      name: 'Listin (Community)',
      category: 'Web App',
      url: 'portfolio',
      imageUrl: 'assets/images/home_page/portfolio_section/project_14.png',
    },
  ];

  useEffect(() => {
    const updateDescriptionDisplay = () => {
      const filteredTabs = tabs.find((tab) => tab.id === currentTab);
      const filtereredProjects = projectList.filter((prj) => prj.category === currentTab);

      setDescriptionDisplay({
        title: filteredTabs?.title,
        desc: filteredTabs?.desc,
        portolio: filtereredProjects,
      });
    };

    updateDescriptionDisplay();
  }, [currentTab]);

  const handleChangeCurretTab = useCallback((newTab) => {
    setCurrentTab(newTab);
  }, []);

  const handleChangeSwiperIndex = useCallback(
    (index) => {
      swiperRef.current?.slideTo(index);
      setSwiperIndex(index);
    },
    [swiperRef]
  );

  return (
    <section id="our_portfolio" className="px-[50px] pt-[75px] lg:px-[100px] lg:pt-[160px]">
      <div className="">
        <p className="kanit-bold text-center text-3xl text-white md:text-4xl">Our Best Portfolio</p>
      </div>

      <div className="relative mt-[50px] flex flex-col justify-between gap-20 lg:flex-row">
        <div className="flex flex-col items-start justify-start rounded-2xl bg-gradient-to-b from-[#9A4BFF]/10 to-transparent p-10 lg:basis-1/2 ">
          <h3 className="kanit-bold text-center text-[24px] capitalize leading-[150%] text-keeps-us-going-white lg:text-[36px]">{descriptionDisplay?.title}</h3>
          <p className="kanit-light mb-[26px] mt-4 max-w-[530px] text-base capitalize leading-[150%] text-[#B0B0B0]">{descriptionDisplay?.desc}</p>

          <ul className="flex list-inside flex-col gap-6 text-[#514764] md:list-disc">
            {currentTab !== 'Mobile App' && (
              <li>
                <button
                  aria-label="button-action"
                  type="button"
                  onClick={() => handleChangeCurretTab('Mobile App')}
                  className="kanit-medium text-left text-2xl capitalize leading-[150%] text-[#514764]"
                >
                  Mobile App Development
                </button>
              </li>
            )}

            {currentTab !== 'Web App' && (
              <li>
                <button aria-label="button-action" type="button" onClick={() => handleChangeCurretTab('Web App')} className="kanit-medium text-left text-2xl capitalize leading-[150%] text-[#514764]">
                  Web App Development
                </button>
              </li>
            )}

            {currentTab !== 'Marketing Digital' && (
              <li>
                <button
                  aria-label="button-action"
                  type="button"
                  onClick={() => handleChangeCurretTab('Marketing Digital')}
                  className="kanit-medium text-left text-2xl capitalize leading-[150%] text-[#514764]"
                >
                  Marketing Digital
                </button>
              </li>
            )}
          </ul>
        </div>

        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          className="mySwiper max-w-[285px] p-2 md:max-w-screen-sm lg:basis-1/2"
        >
          {descriptionDisplay?.portolio?.map((portfolio, i) => (
            <SwiperSlide key={i} className="w-[530px] rounded-2xl bg-gradient-to-b from-[#9A4BFF]/10 to-transparent p-5 ring-1 ring-[#9A4BFF]/50">
              <img src={portfolio.imageUrl} alt={portfolio.name} className="h-[250px] w-full rounded-2xl object-contain object-center md:h-[350px]" />

              <div className="mt-10 rounded-2xl bg-gradient-to-br from-primary to-secondary p-[1px] shadow-2xl shadow-secondary/40">
                <div className="flex flex-col items-center justify-between gap-3 rounded-2xl bg-[#231835] p-5 md:flex-row md:gap-0">
                  <div className="flex flex-col items-center gap-2 md:items-start">
                    <p className="kanit-bold text-center text-[22px] text-white md:text-left lg:max-w-[290px] lg:truncate">{portfolio.name}</p>
                    <p className="kanit-light text-sm text-white">{portfolio.category}</p>
                  </div>
                  {portfolio.url !== 'portfolio' ? (
                    <a
                      className="kanit-bold whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-secondary/30"
                      href={`/portfolio-detail/${portfolio.url}`}
                    >
                      Study Case
                    </a>
                  ) : (
                    <button
                      aria-label="button-action"
                      className="kanit-bold whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-secondary/30"
                    >
                      Study Case
                    </button>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* SWIPER PAGINATION */}
        <div className="absolute bottom-[230px] right-[50%] z-20 flex translate-x-[50%] items-center justify-center gap-2 md:bottom-[150px] lg:right-[200px] lg:translate-x-0">
          {descriptionDisplay?.portolio?.map((_, i) => (
            <button aria-label="button-action" key={i} onClick={() => handleChangeSwiperIndex(i)}>
              <div className={twMerge('aspect-auto h-[12px] w-[12px] rounded-full bg-white', i !== swiperIndex && 'bg-white/30')} />
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurPortfolio;
