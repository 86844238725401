import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useLocation } from 'react-router-dom';

function Navbar() {
  const [isShowNavbarDesktop, setIsShowNavbarDesktop] = useState(true);

  const { pathname } = useLocation();

  const handleScroll = () => {
    let lastScroll = 0;

    return function () {
      let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0 && lastScroll <= currentScroll) {
        lastScroll = currentScroll;
        setIsShowNavbarDesktop(false);
      } else {
        lastScroll = currentScroll;
        setIsShowNavbarDesktop(true);
      }
    };
  };

  useEffect(() => {
    const scrollListener = handleScroll();

    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  const [isOpenNavbar, setIsOpenNavbar] = useState(false);
  const toggleNavbar = () => {
    setIsOpenNavbar((current) => !current);
  };

  return (
    <nav
      className={`top-0 z-50 flex h-[104px] w-full flex-col items-center justify-center border-white/10 px-[50px] transition-all delay-75 xl:px-[100px] ${isShowNavbarDesktop ? 'opacity-100' : 'opacity-0'}`}
    >
      <div className="relative z-20 flex w-full flex-row items-center justify-between">
        <img src="/assets/images/global/logo.svg" alt="logo-navbar-exenesia" />

        <div className="hidden items-center justify-center gap-x-9 xl:flex">
          <a href="/" className={twMerge('kanit-regular text-base capitalize leading-[150%] text-purple-secondary', pathname === '/' && 'text-white')}>
            Home
          </a>

          <a href="/about" className={twMerge('kanit-regular text-base capitalize leading-[150%] text-purple-secondary', pathname === '/about' && 'text-white')}>
            About us
          </a>

          <a
            href="/portfolio"
            className={twMerge(
              'kanit-regular text-base capitalize leading-[150%] text-purple-secondary',
              pathname === '/portfolio' || (pathname.includes('/portfolio-detail') && pathname.startsWith('/portfolio-detail/')) ? 'text-white' : ''
            )}
          >
            Portfolio
          </a>

          <a href="https://blog.exenesia.com" className={twMerge('kanit-regular text-base capitalize leading-[150%] text-purple-secondary', pathname === '/blogs' && 'text-white')}>
            Blog
          </a>

          <RenderDopdownServicesLink pathname={pathname} />

          <RenderDopdownProductLink pathname={pathname} />

          <a href="/academy" className={twMerge('kanit-regular text-base capitalize leading-[150%] text-purple-secondary', pathname === '/academy' && 'text-white')}>
            academy
          </a>
        </div>

        <a
          href="/contact"
          className="kanit-bold hidden whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-5 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-neutral-800/70 xl:block"
        >
          Contact Us
        </a>

        <button aria-label="button-action" type="button" className="block border-[0.5px] border-service-border p-1 xl:hidden" onClick={toggleNavbar}>
          <img src="/assets/images/global/hamburger.svg" alt="icon-hamburger" />
        </button>
      </div>

      <div className={`${isOpenNavbar ? 'top-[8rem]' : ' -top-[300px]'} delay-400 absolute w-full items-center justify-center bg-[#170C2B]  pb-8 transition-all xl:hidden `}>
        <div
          className={`${isOpenNavbar ? 'flex' : 'hidden'}  flex-col items-center justify-center gap-x-9 gap-y-4 pb-5`}
          style={{
            backgroundImage: `url('/assets/images/home_page/bg_gradient.svg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'right bottom',
          }}
        >
          <a href="/" className={twMerge('kanit-bold text-base capitalize leading-[150%] text-purple-secondary', pathname === '/' && 'text-white')}>
            Home
          </a>

          <a href="/about" className={twMerge('kanit-bold text-base capitalize leading-[150%] text-purple-secondary', pathname === '/about' && 'text-white')}>
            About us
          </a>

          <a
            href="/portfolio"
            className={twMerge(
              'kanit-bold text-base capitalize leading-[150%] text-purple-secondary',
              pathname === '/portfolio' || (pathname.includes('/portfolio-detail') && pathname.startsWith('/portfolio-detail/')) ? 'text-white' : ''
            )}
          >
            portfolio
          </a>

          <a href="https://blog.exenesia.com/" className={twMerge('kanit-bold text-base capitalize leading-[150%] text-purple-secondary', pathname === '/blogs' && 'text-white')}>
            Blogs
          </a>

          <RenderDopdownServicesLink pathname={pathname} />

          <RenderDopdownProductLink pathname={pathname} />

          <a href="/academy" className={twMerge('kanit-regular text-base capitalize leading-[150%] text-purple-secondary', pathname === '/academy' && 'text-white')}>
            academy
          </a>

          <a href="/contact" className="kanit-bold flex whitespace-nowrap rounded-lg bg-gradient-to-r from-secondary to-primary px-5 py-[10px] text-base leading-[150%] text-grey-secondary">
            Contact Us
          </a>
        </div>
      </div>
    </nav>
  );
}

function RenderDopdownServicesLink({ pathname }) {
  return (
    <div className="dropdown dropdown-hover">
      <div
        tabIndex={0}
        className={twMerge(
          'kanit-bold cursor-pointer text-base capitalize leading-[150%] text-purple-secondary',
          (pathname === '/service/web-development' || pathname === '/service/specialist-mobile') && 'text-white'
        )}
      >
        Service
      </div>
      <div className="dropdown-content bg-transparent pt-3">
        <ul tabIndex={0} className="menu z-[1] w-52 rounded-box bg-base-100 p-2 shadow">
          <li>
            <a
              href="/service/web-development"
              className={twMerge(
                'kanit-bold text-sm capitalize leading-[150%] text-purple-secondary/80',
                pathname === '/service/web-development' && 'bg-gradient-to-r from-secondary to-primary bg-clip-text text-transparent'
              )}
            >
              Web Development
            </a>
          </li>
          <li>
            <a
              href="/service/specialist-mobile"
              className={twMerge(
                'kanit-regular text-sm capitalize leading-[150%] text-purple-secondary/80',
                pathname === '/service/specialist-mobile' && 'bg-gradient-to-r from-secondary to-primary bg-clip-text text-transparent'
              )}
            >
              Specialist Mobile
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

function RenderDopdownProductLink({ pathname }) {
  return (
    <div className="dropdown dropdown-hover">
      <div
        tabIndex={0}
        className={twMerge(
          'kanit-bold cursor-pointer text-base capitalize leading-[150%] text-purple-secondary',
          (pathname === '/product/easy-on-the-journey' || pathname === '/product/bikin-usaha') && 'text-white'
        )}
      >
        Product
      </div>
      <div className="dropdown-content bg-transparent pt-3">
        <ul tabIndex={0} className="menu z-[1] w-52 rounded-box bg-base-100 p-2 shadow">
          <li>
            <a
              href="/product/easy-on-the-journey"
              className={twMerge(
                'kanit-bold text-sm capitalize leading-[150%] text-purple-secondary/80',
                pathname === '/product/easy-on-the-journey' && 'bg-gradient-to-r from-secondary to-primary bg-clip-text text-transparent'
              )}
            >
              Easy on the journey
            </a>
          </li>
          <li>
            <a
              href="https://dev-web.keansignature.com"
              className={twMerge(
                'kanit-bold text-sm capitalize leading-[150%] text-purple-secondary/80',
                pathname === '/product' && 'bg-gradient-to-r from-secondary to-primary bg-clip-text text-transparent'
              )}
            >
              Bikin Usaha
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
