import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

// swiper
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// REACT COMPONENT
import HomeView from './views/HomeView';
import AboutView from './views/AboutView';
import BlogsView from './views/BlogsView';
import BlankView from './views/BlankView';
import AcademyView from './views/AcademyView';
import ContactView from './views/ContactView';
import PortfolioView from './views/PortfolioView';
import ServiceWebView from './views/service/ServiceWebView';
import ServiceMobileView from './views/service/ServiceMobileView';
import ProductEvent from './views/product/ProductEvent';
import PortofolioMobileDetail from './views/portoflio-detail/PortofolioMobileDetail';

// REACT ROUTER
const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomeView />} />
        <Route exact path="/about" element={<AboutView />} />
        <Route exact path="/portfolio" element={<PortfolioView />} />
        <Route exact path="/portfolio-detail/:portfolio" element={<PortofolioMobileDetail />} />
        <Route exact path="/blogs" element={<BlogsView />} />
        <Route exact path="/service/web-development" element={<ServiceWebView />} />
        <Route exact path="/service/specialist-mobile" element={<ServiceMobileView />} />
        <Route exact path="/product/easy-on-the-journey" element={<ProductEvent />} />
        <Route exact path="/academy" element={<AcademyView />} />
        <Route exact path="/contact" element={<ContactView />} />
        <Route exact path="/blank" element={<BlankView />} />
      </Routes>
    </Router>
  );
};

export default App;
