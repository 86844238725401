const ContentMockup = (props) => {
  const portfolioData = props.data;
  // const title = props.param;
  return (
    <>
      {portfolioData.mockup.map((itemMockup, index) => (
        <>
          {itemMockup.type === 'mobile' ? (
            <section className="px-10 pt-12 md:px-[50px] lg:mb-[100px] lg:px-[100px] lg:pt-[160px]" key={index}>
              <div className="flex flex-col">
                <div className="w-fulls flex flex-col lg:flex-row lg:gap-14">
                  <div className="relative w-full rounded-2xl bg-gradient-to-b from-[#9A4BFF]/10 to-transparent p-5 ring-1 ring-[#9A4BFF]/50 lg:grid-cols-3 lg:py-10">
                    <div className="flex w-full justify-center gap-[50px]">
                      {itemMockup.view.map((viewFile, index) => (
                        <div className="lg:mt-[-100px]" key={index}>
                          <img src={viewFile.file} alt={viewFile.file} />
                        </div>
                      ))}
                    </div>
                    <h1 className="text-kanit kanit-bold mt-4 text-center text-[20px] text-white lg:mt-10 lg:text-[25px]">{itemMockup.title}</h1>
                  </div>
                </div>
              </div>
            </section>
          ) : itemMockup.type === 'web' ? (
            <section className="px-10 pt-12 md:px-[50px] lg:mb-[100px] lg:px-[100px] lg:pt-[160px]" key={index}>
              <div className="flex flex-col">
                <div className="w-fulls flex flex-col lg:flex-row lg:gap-14">
                  <div className="relative flex w-full justify-center gap-[50px] rounded-2xl bg-gradient-to-b from-[#9A4BFF]/10 to-transparent p-5 ring-1 ring-[#9A4BFF]/50 lg:grid-cols-3 lg:py-10">
                    <div className="lg:mt-[-100px]">
                      {itemMockup.view.map((viewFile, index) => (
                        <div key={index}>
                          <img src={viewFile.file} alt={viewFile.file} />
                        </div>
                      ))}
                      <h1 className="text-kanit kanit-bold mt-4 text-center text-[20px] text-white lg:mt-10 lg:text-[25px]">{itemMockup.title}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
        </>
      ))}

      <section className="px-10 pt-12 md:px-[50px] lg:px-[100px] lg:pt-[160px]">
        <div className="flex flex-col">
          <div className="w-fulls flex flex-col lg:flex-row lg:gap-14">
            <div className="gap-50 relative w-full items-center justify-center rounded-2xl bg-gradient-to-b from-[#9A4BFF]/10 to-transparent p-5 ring-1 ring-[#9A4BFF]/50 lg:flex lg:grid-cols-3 lg:px-[50px] lg:py-[100px]">
              <div className="mx-auto mb-10 w-full lg:mb-0 lg:w-1/2">
                <h1 className="text-kanit kanit-bold text-[25px] text-white">{portfolioData.platform_title}</h1>
                <p className="text-kanit kanit-normal mt-5 text-[16px] text-white">{portfolioData.platform_description}</p>
              </div>
              <div className="flex w-full justify-center lg:w-1/2 lg:justify-end">
                {portfolioData.platform_web === true ? (
                  <img src="/assets/portofolio/platform/icon-dekstop.png" className="mx-3 h-[70px] w-full max-w-[70px] lg:mx-10 lg:max-w-[70px]" alt="icon-dekstop.png" />
                ) : null}
                {portfolioData.platform_tablet === true ? (
                  <img src="/assets/portofolio/platform/icon-tablet.png" className="mx-3 h-[70px] w-full max-w-[70px] lg:mx-10 lg:max-w-[70px]" alt="icon-tablet.png" />
                ) : null}
                {portfolioData.platform_phone === true ? (
                  <img src="/assets/portofolio/platform/icon-phone.png" className="mx-3 h-[70px] w-full max-w-[70px] lg:mx-10 lg:max-w-[70px]" alt="icon-phone.png" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContentMockup;
