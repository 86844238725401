// import { useState } from 'react';
// import { twMerge } from 'tailwind-merge';
// import { Pagination } from 'swiper/modules';
// import { Swiper, SwiperSlide } from 'swiper/react';

const AboutTeams = () => {
  // const [swiperIndex, setSwiperIndex] = useState(0);

  // const teamPhotos = [
  //   'assets/images/about_page/teams_section/default_image.png',
  //   'assets/images/about_page/teams_section/default_image.png',
  //   'assets/images/about_page/teams_section/default_image.png',
  //   'assets/images/about_page/teams_section/default_image.png',
  //   'assets/images/about_page/teams_section/default_image.png',
  // ];

  return <></>;

  // return (
  //   <section className="pt-[100px]">
  //     <div className="">
  //       <p className="kanit-bold text-center text-3xl text-white md:text-4xl">Exenesia Best of Team</p>
  //     </div>

  //     <div className="mt-[100px]">
  //       <Swiper
  //         slidesPerView={4}
  //         centeredSlides={true}
  //         pagination={{ clickable: true }}
  //         spaceBetween={30}
  //         loop={true}
  //         rewind={true}
  //         modules={[Pagination]}
  //         onActiveIndexChange={(swiper) => setSwiperIndex(swiper.realIndex)}
  //         className="mySwiper py-10"
  //       >
  //         {teamPhotos.map((photo, i) => (
  //           <SwiperSlide key={i}>
  //             <div className={twMerge('flex h-[373px] w-[308px] scale-90 items-center justify-center rounded-[42px] bg-white/30', swiperIndex === i && 'scale-[1.2] bg-white')}>
  //               <img src={photo} alt={`photo-${i + 1}`} />
  //               <span>{i + 1}</span>
  //             </div>
  //           </SwiperSlide>
  //         ))}
  //       </Swiper>
  //     </div>
  //   </section>
  // );
};

export default AboutTeams;
