
import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './App';

// SETUP TAILWIND
import './index.css'

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


