import React from 'react';
// components
import Layout from '../../components/global/Layout';
import DocumentTitle from '../../components/global/DocumentTitle';
//
import BannerHero from '../../components/portfolio_detail_page/BannerHero';
import IdentityApps from '../../components/portfolio_detail_page/IdentityApps';
// import DescriptionApps from '../../components/portfolio_detail_page/DescriptionApps';
import ContentMockup from '../../components/portfolio_detail_page/ContentMoackup';
import SubscribePage from '../../components/portfolio_detail_page/SubscribePage';
import { useParams } from 'react-router-dom';
import useFetchData from '../../hooks/getData';
// import BlankView from '../BlankView';
import { RevolvingDot } from 'react-loader-spinner';

const PortofolioMobileDetail = () => {
  let { portfolio } = useParams();
  DocumentTitle(`Portfolio - ${portfolio}`);
  const portfolioData = useFetchData();
  const filteredData = portfolioData.filter((item) => item.url === portfolio);
  if (filteredData.length === 0) {
    return (
      <>
        <div className="flex h-screen items-center justify-center bg-gradient-to-b from-[#160B29] to-[#B30195] p-5 ring-1">
          <RevolvingDot visible={true} height="80" width="80" color="#160B29" ariaLabel="revolving-dot-loading" wrapperStyle={{}} wrapperClass="" />
        </div>
      </>
    );
  }

  return (
    <Layout>
      <BannerHero param={portfolio} data={filteredData[0]} />
      <IdentityApps param={portfolio} data={filteredData[0]} />
      <ContentMockup param={portfolio} data={filteredData[0]} />
      <SubscribePage />
    </Layout>
  );
};

export default PortofolioMobileDetail;
