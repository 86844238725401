const AboutLife = () => {
  const lifeList = [
    {
      imageUrl: 'assets/images/about_page/life_section/photo_1.png',
      title: 'Starting to growth',
      date: '1 Januari 2020',
    },
    {
      imageUrl: 'assets/images/about_page/life_section/photo_2.png',
      title: 'First meet with team',
      date: '1 Februari 2024',
    },
    {
      imageUrl: 'assets/images/about_page/life_section/photo_3.png',
      title: 'Yearly meetup',
      date: '1 Januari 2021',
    },
    {
      imageUrl: 'assets/images/about_page/life_section/photo_4.png',
      title: 'Buka bersama 2023',
      date: '10 November 2023',
    },
    {
      imageUrl: 'assets/images/about_page/life_section/photo_5.png',
      title: 'Meetup with partnership',
      date: '1 Januari 2020',
    },
    {
      imageUrl: 'assets/images/about_page/life_section/photo_6.png',
      title: 'Seminar Tech Track ',
      date: '10 November 2024',
    },
  ];

  return (
    <section className="px-[50px] pt-[100px] lg:px-[100px]">
      <div className="">
        <p className="kanit-bold text-center text-3xl text-white md:text-4xl">Life in Exenesia</p>
      </div>

      <div className="mt-[50px] grid grid-cols-1 gap-20 lg:grid-cols-2">
        {lifeList.map((life, i) => (
          <div key={i} className="w-full rounded-2xl bg-gradient-to-b from-[#9A4BFF]/10 to-transparent p-5 ring-1 ring-[#9A4BFF]/50">
            <img src={life.imageUrl} alt={life.title} className="h-[200px] w-full rounded-2xl object-cover object-center md:h-[286px]" />

            <div className="mt-10 rounded-2xl bg-gradient-to-br from-primary to-secondary p-[1px] shadow-2xl shadow-secondary/40">
              <div className="flex flex-col items-center justify-between gap-3 rounded-2xl bg-[#231835] p-5 md:flex-row md:gap-0">
                <div className="flex flex-col items-center gap-2 md:items-start">
                  <p className="kanit-bold text-center text-[22px] text-white md:text-left lg:max-w-[290px] lg:truncate">{life.title}</p>
                  <p className="kanit-light text-sm text-white">{life.date}</p>
                </div>

                <button
                  aria-label="button-action"
                  className="kanit-bold whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-secondary/30"
                >
                  See Photo
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AboutLife;
