import React, { useEffect, useState } from 'react';
// components
import Layout from '../components/global/Layout';
import DocumentTitle from '../components/global/DocumentTitle';
import LoadingScreen from '../components/global/LoadingScreen';
//
import AcademyHero from '../components/academy_page/AcademyHero';
import AcademyClass from '../components/academy_page/AcademyClass';
import AcademyEvent from '../components/academy_page/AcademyEvent';
import BlogsSubscription from '../components/blogs_page/BlogsSubscription';

const AcademyView = () => {
  const [loading, setLoading] = useState(true);

  DocumentTitle('Academy');

  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 3000);
  // }, []);

  // if (loading) return <LoadingScreen />;

  return (
    <Layout>
      <AcademyHero />
      <AcademyClass />
      <AcademyEvent />
      <BlogsSubscription />
    </Layout>
  );
};

export default AcademyView;
