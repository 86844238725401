import { useEffect, useState } from 'react';
// components
import Layout from '../components/global/Layout';
import DocumentTitle from '../components/global/DocumentTitle';
import LoadingScreen from '../components/global/LoadingScreen';
//
import AboutHero from '../components/about_page/AboutHero';
import AboutLife from '../components/about_page/AboutLife';
import AboutTeams from '../components/about_page/AboutTeams';
import AboutHistory from '../components/about_page/AboutHistory';
import AboutProducts from '../components/about_page/AboutProducts';
import AboutSubscription from '../components/about_page/AboutSubscription';

const AboutView = () => {
  const [loading, setLoading] = useState(true);

  DocumentTitle('About Us');

  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 3000);
  // }, []);

  // if (loading) return <LoadingScreen />;

  return (
    <Layout>
      <AboutHero />
      <AboutHistory />
      <AboutProducts />
      <AboutTeams />
      <AboutLife />
      <AboutSubscription />
    </Layout>
  );
};

export default AboutView;
