const Roadmap = () => {
  const histories = [
    {
      icon: '/assets/images/product_page/pre-event.png',
      title: 'Pre event',
      desc: 'Our webinar helps the younger generation improve quality in the world of IT and Digital Marketing',
    },
    {
      icon: '/assets/images/product_page/pre-event.png',
      title: 'road event',
      desc: 'Our workshops are a form of dedication to students to gain insight into the work industry',
    },
    {
      icon: '/assets/images/product_page/pre-event.png',
      title: 'main event',
      desc: 'Our seminar invites great practitioners and how IT and Digital Marketing are combined',
    },
  ];

  return (
    <section className="flex flex-col items-center justify-center px-10 pt-[75px] md:px-[50px] lg:rounded-tl-[200px] lg:rounded-tr-[200px] lg:px-[100px] lg:pt-[160px]">
      <p className="kanit-bold leading-auto text-center text-3xl capitalize tracking-[1px] text-[#FFF] md:text-4xl">Roadmap Easy on the journey</p>

      <div className="mt-[50px] flex max-w-lg flex-col items-center justify-between gap-10 lg:max-w-6xl lg:flex-row lg:items-start lg:gap-[56px]">
        {histories.map((history, i) => (
          <div key={i} className="flex basis-1/3 flex-col items-center justify-center gap-3">
            <div className="relative">
              <div className="flex h-[110px] w-[110px] items-center justify-center rounded-full bg-gradient-to-br from-transparent to-[#160B29] drop-shadow-lg">
                <img src={history.icon} alt={history.icon} />
              </div>

              {i !== histories.length - 1 && (
                <img src="/assets/images/about_page/history_section/dashed.png" alt="dashed.png" className="absolute left-[160px] top-[50px] hidden min-w-[200px] max-w-[200px] lg:block" />
              )}
            </div>

            <p className="kanit-bold text-2xl text-white">{history.title}</p>
            <p className="kanit-light text-center text-[#B0B0B0]">{history.desc}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Roadmap;
