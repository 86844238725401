import { useState, useEffect } from 'react';

const useFetchData = () => {
  const [data, setData] = useState([]);

  const getData = () => {
    fetch('/data/portofolio.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(function (response) {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
      })
      .catch(function (error) {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return data;
};

export default useFetchData;
