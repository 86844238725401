const BannerMediaSocial = () => {
  const iconMediaSocial = [
    {
      link: 'https://www.instagram.com/easyonthejourney',
      icon: '/assets/images/product_page/icon/icon-instagram.png',
    },
    {
      link: '',
      icon: '/assets/images/product_page/icon/icon-tiktok.png',
    },
    {
      link: '',
      icon: '/assets/images/product_page/icon/icon-youtube.png',
    },
  ];
  const bgHero = '/assets/images/product_page/bg-product.png';

  return (
    <section className="kanit grid grid-cols-1 gap-16 px-10 pt-[75px] md:px-[50px] lg:grid-cols-1 lg:px-[100px] lg:pt-[160px]">
      <div
        className="flex flex-col items-center justify-between gap-3 rounded-2xl bg-[#231835] bg-cover p-5 shadow-2xl shadow-secondary/40 md:flex-row md:gap-0 md:p-10"
        style={{ backgroundImage: `url(${bgHero})` }}
      >
        <div className="">
          <h3 className="kanit-bold mb-5 text-wrap bg-gradient-to-r from-[#7622C7] via-[#EC5D81] to-[#F69605] bg-clip-text text-center text-4xl font-bold text-transparent md:mb-0 md:text-left md:text-[45px]">
            Easy on the journey
          </h3>
          <p className="kanit-light mb-0 text-center text-sm text-[#000000] md:w-[416px] md:text-left md:leading-[220%]">Ayo bangun karir impian anda bersama kami!</p>
        </div>

        <div className="flex gap-3">
          {iconMediaSocial.map((datas, i) => (
            <a href={datas.link}>
              <img src={datas.icon} className="w-[70px]" alt={datas.icon} />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BannerMediaSocial;
