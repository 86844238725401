import Footer from './Footer';
import Navbar from './Navbar';
import BackToTop from './BackToTop';

function Layout({ children }) {
  return (
    <div
      className="pt-[32px]"
      style={{
        backgroundImage: `url('/assets/images/home_page/bg_gradient.svg')`,
        backgroundSize: 'cover',
      }}
    >
      <BackToTop />
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  );
}

export default Layout;
