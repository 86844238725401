import React, { useEffect } from 'react';

function AutoScroller({ cards, direction }) {
    useEffect(() => {
        if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
            addAnimation(); 
        }
    }, []);

    function addAnimation() {
        const scrollers = document.querySelectorAll(".scroller");
        scrollers.forEach((scroller) => {
            scroller.setAttribute("data-animated", true);
            const scrollerInner = scroller.querySelector(".scroller__inner");
            const scrollerContent = Array.from(scrollerInner.children);
            scrollerContent.forEach((item) => {
                const duplicatedItem = item.cloneNode(true);
                duplicatedItem.setAttribute("aria-hidden", true);
                scrollerInner.appendChild(duplicatedItem);
            });
        });
    }

    return (
        <div className="scroller" data-direction={direction} data-speed="slow">
            <div className="scroller__inner">
                {cards}
            </div>
        </div>
    );
}

export default AutoScroller;