const Solution = () => {
  const solutionList = [
    {
      logoUrl: '/assets/images/home_page/solution_section/mobile.svg',
      title: 'Product Solution',
      desc: 'We help you solve a problem in your product, whether from a B2C & B2B perspective, we can help you innovate your product',
    },
    {
      logoUrl: '/assets/images/home_page/solution_section/desktop.svg',
      title: 'Software development',
      desc: "Now you don't need to be confused about presenting digital ideas/innovations for your business, we are ready to help make it all happen",
    },
    {
      logoUrl: '/assets/images/home_page/solution_section/digital_marketing.svg',
      title: 'marketing digital',
      desc: 'With us, your business or product can be helped with marketing and sales digitally',
    },
  ];

  return (
    <section id="solution" className="px-[50px] pt-[75px] lg:px-[100px] lg:pt-[160px]">
      <div className="">
        <p className="kanit-bold text-center text-3xl capitalize text-white md:text-4xl">We are the solution for you</p>
      </div>

      <div className="container m-auto mt-[50px] grid grid-cols-1 gap-[20px] lg:grid-cols-3">
        {solutionList.map((solution, i) => (
          <div key={i} className="flex flex-col items-center justify-center">
            <div className="relative z-[1] flex h-[84px] w-[84px] items-center justify-center rounded-full bg-[#291F3A]">
              <img src={solution.logoUrl} alt={solution.title} />
            </div>

            <div className="-mt-10 flex h-[293px] flex-col items-center justify-start rounded-[14px] bg-gradient-to-b from-service-white to-service-purple px-5 pt-[76px]">
              <h4 className="kanit-bold leading-auto mb-[3px] mt-[0px] text-center text-[22px] capitalize tracking-[1px] text-white">{solution.title}</h4>
              <p className="kanit-light text-center text-base leading-[180%] text-[#B0B0B0]">{solution.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Solution;
