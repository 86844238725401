function Footer() {
  return (
    <footer className="flex flex-col items-center justify-center px-[50px] pb-[22px] lg:px-[100px]">
      <div className="flex w-full flex-col items-start justify-between gap-y-10 lg:flex-row lg:gap-y-0">
        <div className="flex flex-col items-start justify-start">
          <img src="/assets/images/global/logo.svg" alt="logo-exenesia" />
          <span className="kanit-light mt-[13px] max-w-[236px] text-xs leading-[170%] text-[#E6E6E6]">
            Kami adalah software house yang berdiri sejak tahun 2020 kami bergerak di bidang perangkat lunak
          </span>
        </div>

        <div className="flex flex-wrap items-start justify-start gap-x-[54px] gap-y-10 lg:flex-nowrap lg:gap-y-0">
          <div className="flex flex-col items-start justify-start">
            <h6 className="kanit-bold leading-auto mb-[13px] text-base capitalize text-[#E6E6E6]">Layanan Kami</h6>
            <a href="/service/web-development" className="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              Website
            </a>
            <a href="/service/specialist-mobile" className="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              Mobile Apps
            </a>
            <a href="/" className="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              System Digital
            </a>
            <a href="mailto:sales@exenesia.com" className="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              Consultan
            </a>
          </div>
          <div className="flex flex-col items-start justify-start">
            <h6 className="kanit-bold leading-auto mb-[13px] text-base capitalize text-[#E6E6E6]">Product</h6>
            <a href="https://dev-web.keansignature.com" className="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              bikinusaha.com
            </a>
          </div>
          <div className="flex flex-col items-start justify-start">
            <h6 className="kanit-bold leading-auto mb-[13px] text-base capitalize text-[#E6E6E6]">Portofolio</h6>
            <a href="/service/web-development" className="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              Website
            </a>
            <a href="/service/specialist-mobile" className="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              Mobile Apps
            </a>
            <a href="/" className="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              System Digital
            </a>
          </div>
          <div className="flex flex-col items-start justify-start">
            <h6 className="kanit-bold leading-auto mb-[13px] text-base capitalize text-[#E6E6E6]">Cerita Kami</h6>
            {/* <a href="/" className="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              Gallery
            </a> */}
            <a href="/portfolio" className="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              Portfolio
            </a>
            <a href="https://www.instagram.com/exenesia_" className="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              Social Media
            </a>
            <a href="https://blog.exenesia.com" className="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              Blog
            </a>
          </div>
          <div className="flex flex-col items-start justify-start">
            <h6 className="kanit-bold leading-auto mb-[13px] text-base capitalize text-[#E6E6E6]">Hubungi</h6>
            <a href="https://wa.me/+628175141004" className="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              +62 817-514-1004
            </a>
            <a href="/" className="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              exenesia.id
            </a>
            <a href="mailto:admin@exenesia.com" class="kanit-light leading-auto mb-[8px] text-[13px] capitalize text-[#E6E6E6]">
              admin@exenesia.com
            </a>
          </div>
        </div>
      </div>
      <p className="kanit-light leading-auto mt-[78px] text-[13px] capitalize text-white">©2024 PT Exenesia Digital Solution. Seluruh Hak Cipta.</p>
    </footer>
  );
}

export default Footer;
