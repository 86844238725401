const AcademyEvent = () => {
  const EventList = [
    {
      releaseDate: '06 April 2024',
      title: 'Easy on the journey : digitalization transition iT',
      shortDesc: 'Webinar peran penting Digital Marketing dengan  IT',
      imageUrl: '/assets/images/academy_page/event/journey-class.png',
      start_time: '09.00',
      type: 'Webinar',
      end_time: '12.00',
      time_zone: 'WIB',
      register: 0,
    },
    {
      releaseDate: '06 April 2024',
      title: 'Easy on the journey : Build your busines 4.0 era',
      shortDesc: 'Seminar pengenalan Digital Marketing dengan Transisi IT',
      imageUrl: '/assets/images/academy_page/event/comming-soon.png',
      start_time: '09.00',
      type: 'Seminar',
      end_time: '12.00',
      time_zone: 'WIB',
      register: 1,
    },
  ];

  return (
    <section>
      <div className="flex flex-col gap-16 px-10 pt-[75px] md:px-[50px] lg:px-[100px] lg:pt-[160px]">
        {EventList?.map((item, i) => (
          <div key={i} className="flex flex-col gap-7 lg:flex-row lg:gap-14">
            <div className="relative w-full rounded-2xl bg-gradient-to-b from-[#9A4BFF]/10 to-transparent p-5 ring-1 ring-[#9A4BFF]/50 lg:w-fit">
              <img src={item.imageUrl} alt={item.title} className="h-[286px] w-full object-cover object-center lg:w-[475px]" />
            </div>

            <div className="flex flex-1 flex-col gap-3">
              <p className="kanit-bold mb-3 text-3xl leading-normal text-white">{item.title}</p>
              <p className="kanit-light mb-3 text-sm leading-loose text-white">{item.shortDesc}</p>

              <div className="mb-3 grid w-full grid-cols-1 rounded-2xl bg-gradient-to-b from-[#9A4BFF]/10 to-transparent p-2 ring-1 ring-[#9A4BFF]/50 md:grid-cols-1 xl:grid-cols-3">
                <div className="mb-3 flex justify-center lg:mb-0 xl:justify-start">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.75 2.25C6.94891 2.25 7.13968 2.32902 7.28033 2.46967C7.42098 2.61032 7.5 2.80109 7.5 3V4.5H16.5V3C16.5 2.80109 16.579 2.61032 16.7197 2.46967C16.8603 2.32902 17.0511 2.25 17.25 2.25C17.4489 2.25 17.6397 2.32902 17.7803 2.46967C17.921 2.61032 18 2.80109 18 3V4.5H18.75C19.5456 4.5 20.3087 4.81607 20.8713 5.37868C21.4339 5.94129 21.75 6.70435 21.75 7.5V18.75C21.75 19.5456 21.4339 20.3087 20.8713 20.8713C20.3087 21.4339 19.5456 21.75 18.75 21.75H5.25C4.45435 21.75 3.69129 21.4339 3.12868 20.8713C2.56607 20.3087 2.25 19.5456 2.25 18.75V7.5C2.25 6.70435 2.56607 5.94129 3.12868 5.37868C3.69129 4.81607 4.45435 4.5 5.25 4.5H6V3C6 2.80109 6.07902 2.61032 6.21967 2.46967C6.36032 2.32902 6.55109 2.25 6.75 2.25ZM20.25 11.25C20.25 10.8522 20.092 10.4706 19.8107 10.1893C19.5294 9.90804 19.1478 9.75 18.75 9.75H5.25C4.85218 9.75 4.47064 9.90804 4.18934 10.1893C3.90804 10.4706 3.75 10.8522 3.75 11.25V18.75C3.75 19.1478 3.90804 19.5294 4.18934 19.8107C4.47064 20.092 4.85218 20.25 5.25 20.25H18.75C19.1478 20.25 19.5294 20.092 19.8107 19.8107C20.092 19.5294 20.25 19.1478 20.25 18.75V11.25Z"
                      fill="white"
                    />
                  </svg>
                  <p className="mx-2 text-[16px] font-light text-white">{item.releaseDate}</p>
                </div>

                <div className="mb-3 flex justify-center lg:mb-0 xl:justify-start">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 2.25C6.615 2.25 2.25 6.615 2.25 12C2.25 17.385 6.615 21.75 12 21.75C17.385 21.75 21.75 17.385 21.75 12C21.75 6.615 17.385 2.25 12 2.25ZM12.75 6C12.75 5.80109 12.671 5.61032 12.5303 5.46967C12.3897 5.32902 12.1989 5.25 12 5.25C11.8011 5.25 11.6103 5.32902 11.4697 5.46967C11.329 5.61032 11.25 5.80109 11.25 6V12C11.25 12.414 11.586 12.75 12 12.75H16.5C16.6989 12.75 16.8897 12.671 17.0303 12.5303C17.171 12.3897 17.25 12.1989 17.25 12C17.25 11.8011 17.171 11.6103 17.0303 11.4697C16.8897 11.329 16.6989 11.25 16.5 11.25H12.75V6Z"
                      fill="white"
                    />
                  </svg>
                  <p className="mx-2 text-[16px] font-light text-white">
                    {item.start_time} - {item.end_time} {item.time_zone}
                  </p>
                </div>

                <div className="mb-3 flex justify-center lg:mb-0 xl:justify-start">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.25 5.25C2.25 4.45435 2.56607 3.69129 3.12868 3.12868C3.69129 2.56607 4.45435 2.25 5.25 2.25H18.75C19.5456 2.25 20.3087 2.56607 20.8713 3.12868C21.4339 3.69129 21.75 4.45435 21.75 5.25V15C21.75 15.7956 21.4339 16.5587 20.8713 17.1213C20.3087 17.6839 19.5456 18 18.75 18H15.75V18.257C15.75 18.854 15.987 19.427 16.409 19.848L17.03 20.47C17.1348 20.5749 17.2061 20.7085 17.235 20.8539C17.2638 20.9993 17.249 21.15 17.1923 21.2869C17.1356 21.4239 17.0395 21.541 16.9163 21.6234C16.7931 21.7058 16.6482 21.7499 16.5 21.75H7.5C7.35176 21.7499 7.20688 21.7058 7.08367 21.6234C6.96045 21.541 6.86442 21.4239 6.80771 21.2869C6.751 21.15 6.73615 20.9993 6.76503 20.8539C6.79392 20.7085 6.86524 20.5749 6.97 20.47L7.591 19.848C8.01271 19.4263 8.24974 18.8544 8.25 18.258V18H5.25C4.45435 18 3.69129 17.6839 3.12868 17.1213C2.56607 16.5587 2.25 15.7956 2.25 15V5.25ZM3.75 5.25V12.75C3.75 13.1478 3.90804 13.5294 4.18934 13.8107C4.47064 14.092 4.85218 14.25 5.25 14.25H18.75C19.1478 14.25 19.5294 14.092 19.8107 13.8107C20.092 13.5294 20.25 13.1478 20.25 12.75V5.25C20.25 4.85218 20.092 4.47064 19.8107 4.18934C19.5294 3.90804 19.1478 3.75 18.75 3.75H5.25C4.85218 3.75 4.47064 3.90804 4.18934 4.18934C3.90804 4.47064 3.75 4.85218 3.75 5.25Z"
                      fill="white"
                    />
                  </svg>
                  <p className="mx-2 text-[16px] font-light text-white">{item.type}</p>
                </div>
              </div>

              <div className="text-center xl:text-start">
                {item.register === 1 ? (
                  <button
                    aria-label="button-action"
                    className="kanit-bold mb-3 whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-secondary/30 xl:mb-0"
                  >
                    Register for now!
                  </button>
                ) : (
                  <div>
                    <button
                      aria-label="button-action"
                      className="kanit-bold mb-3 whitespace-nowrap bg-[#2E2E2E] px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-secondary/30 xl:mb-0"
                    >
                      Not Available
                    </button>
                    <button
                      aria-label="button-action"
                      className="kanit-bold mx-3 whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-secondary/30 xl:mb-0"
                    >
                      View History Event
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AcademyEvent;
