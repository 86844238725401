import AutoScroller from './AutoScroller';

const OurPartner = () => {
  const partnerList = [
    {
      name: 'Homedevs',
      logo: 'assets/images/home_page/partner_section/homedev-partner.png',
    },
    {
      name: 'Begi`s',
      logo: 'assets/images/home_page/partner_section/begis-partner.png',
    },
    {
      name: 'FAUST AEGIS NETWORK',
      logo: 'assets/images/home_page/partner_section/fan-partner.png',
    },
    {
      name: 'robust',
      logo: 'assets/images/home_page/partner_section/robust-partner.png',
    },
    {
      name: 'Syabany Labs',
      logo: 'assets/images/home_page/partner_section/syabany-partner.png',
    },
  ];

  return (
    <section className="px-[50px] pt-[75px] lg:px-[100px] lg:pt-[160px]">
      <div className="">
        <p className="kanit-bold text-center text-3xl text-white md:text-4xl">Our Partner</p>
      </div>

      <div className="mt-[50px]">
        <AutoScroller
          direction="left"
          cards={
            <>
              {partnerList.map((partner, i) => (
                <div key={i} className="via-whte/70 h-[70px] w-[200px] rounded-2xl bg-gradient-to-b from-white/15 to-[#160B29]/30 p-3 drop-shadow-md">
                  <img src={partner.logo} alt={partner.title} className="h-full w-full object-contain object-center" />
                </div>
              ))}
            </>
          }
        />
      </div>
    </section>
  );
};

export default OurPartner;
