const WebPortfolio = () => {
  const projectList = [
    {
      name: 'Halalin Web App',
      category: 'Web App',
      url: 'halal-in',
      imageUrl: '/assets/images/service_page/web/portfolio_section/web_1.svg',
    },
    {
      name: 'Listin (Community)',
      category: 'Web App',
      url: 'portfolio',
      imageUrl: '/assets/images/service_page/web/portfolio_section/web_2.svg',
    },
  ];

  return (
    <section className="px-10 pt-[75px] md:px-[50px] lg:px-[100px] lg:pt-[160px]">
      <div className="">
        <p className="kanit-bold text-center text-3xl text-white md:text-4xl">Our Best Portfolio</p>
      </div>

      <div className="mt-[50px] grid grid-cols-1 gap-16 md:mt-[100px] lg:grid-cols-2">
        {projectList?.map((project, i) => (
          <div key={i} className="rounded-2xl bg-gradient-to-br from-[#9A4BFF] via-[#9A4BFF]/40 to-transparent p-[1px]">
            <div className="w-full rounded-2xl bg-[#160B29] bg-gradient-to-b from-white/10 via-[#160B29]/30 to-[#160B29]/30 p-5">
              <img src={project.imageUrl} alt={project.name} className="h-[250px] w-full rounded-2xl object-contain object-center md:h-[350px]" />

              <div className="mt-10 rounded-2xl bg-gradient-to-br from-primary to-secondary p-[1px] shadow-2xl shadow-secondary/40">
                <div className="flex flex-col items-center justify-between gap-3 rounded-2xl bg-[#231835] p-5 md:flex-row md:gap-0">
                  <div className="flex flex-col items-center gap-2 md:items-start">
                    <p className="kanit-bold text-center text-[22px] text-white md:text-left lg:max-w-[290px] lg:truncate">{project.name}</p>
                    <p className="kanit-light text-sm text-white">{project.category}</p>
                  </div>

                  <button
                    aria-label="button-action"
                    className="kanit-bold whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-secondary/30"
                  >
                    Study Case
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WebPortfolio;
