// components
import Layout from '../components/global/Layout';
import DocumentTitle from '../components/global/DocumentTitle';
//
import ContactMain from '../components/contact_page/ContactMain';
import ContactSubscription from '../components/contact_page/ContactSubscription';

const ContactView = () => {
  DocumentTitle('Contact Us');

  return (
    <Layout>
      <ContactMain />
      <ContactSubscription />
    </Layout>
  );
};

export default ContactView;
