import React from 'react';
// components
import Layout from '../../components/global/Layout';
import DocumentTitle from '../../components/global/DocumentTitle';
//
import BannerHero from '../../components/product_page/BannerHero';
import VisionMission from '../../components/product_page/VisionMission';
import Roadmap from '../../components/product_page/Roadmap';
import AcademyEvent from '../../components/product_page/AcademyEvent';
import OurTeam from '../../components/product_page/OurTeam';
import BannerMediaSocial from '../../components/product_page/BannerMediaSocial';
import GalleryPhoto from '../../components/product_page/GalleryPhoto';
import SubscribePage from '../../components/product_page/SubscribePage';

const ProductEvent = () => {
  DocumentTitle('Product - Easy On The Journey');

  return (
    <Layout>
      <BannerHero />
      <VisionMission />
      <Roadmap />
      <AcademyEvent />
      <OurTeam />
      <BannerMediaSocial />
      <GalleryPhoto />
      <SubscribePage />
    </Layout>
  );
};

export default ProductEvent;
