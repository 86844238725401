import DocumentTitle from '../components/global/DocumentTitle';
const BlankView = () => {
  DocumentTitle('Blank Page');
  return (
    <div className="mx-auto h-[100vh] w-[100%] place-items-center bg-gradient-to-br from-purple-900 to-purple-900 p-[100px] text-center">
      <svg className="mx-auto mb-5" width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M75.0001 42.1875C74.9988 37.0625 76.198 32.0085 78.5016 27.4303C80.8052 22.8522 84.1491 18.8774 88.2655 15.8243C92.3818 12.7712 97.1561 10.7248 102.206 9.84906C107.255 8.97331 112.44 9.29257 117.344 10.7813C118.119 11.0161 118.82 11.4477 119.379 12.0341C119.937 12.6204 120.335 13.3416 120.532 14.1271C120.729 14.9127 120.719 15.7359 120.503 16.5165C120.287 17.2971 119.873 18.0085 119.3 18.5813L98.5501 39.325C98.9439 42.2938 100.275 45.1625 102.556 47.4438C104.838 49.725 107.706 51.0563 110.681 51.4438L131.419 30.7C131.992 30.1275 132.703 29.7131 133.484 29.4973C134.264 29.2815 135.087 29.2716 135.873 29.4686C136.659 29.6656 137.38 30.0628 137.966 30.6215C138.552 31.1801 138.984 31.8812 139.219 32.6563C140.776 37.7866 141.053 43.2205 140.025 48.4825C138.996 53.7445 136.694 58.6744 133.32 62.8408C129.945 67.0071 125.601 70.2831 120.667 72.3821C115.734 74.4812 110.361 75.3392 105.019 74.8813C98.6564 74.3438 93.3314 75.5063 90.5876 78.8438L45.9001 133.125C44.0704 135.336 41.7991 137.141 39.2317 138.423C36.6642 139.706 33.8573 140.438 30.9905 140.573C28.1238 140.708 25.2604 140.243 22.5837 139.208C19.9071 138.172 17.4761 136.589 15.4465 134.56C13.417 132.531 11.8335 130.1 10.7975 127.424C9.76154 124.748 9.29588 121.884 9.43036 119.017C9.56484 116.151 10.2965 113.344 11.5785 110.776C12.8605 108.208 14.6645 105.936 16.8751 104.106L71.1501 59.4125C74.4814 56.6625 75.6501 51.3438 75.1126 44.9813C75.0362 44.052 74.9986 43.1199 75.0001 42.1875ZM25.7314 119.531C25.7314 118.288 26.2253 117.096 27.1043 116.217C27.9834 115.338 29.1757 114.844 30.4189 114.844H30.4689C31.7121 114.844 32.9044 115.338 33.7835 116.217C34.6625 117.096 35.1564 118.288 35.1564 119.531V119.581C35.1564 120.824 34.6625 122.017 33.7835 122.896C32.9044 123.775 31.7121 124.269 30.4689 124.269H30.4189C29.1757 124.269 27.9834 123.775 27.1043 122.896C26.2253 122.017 25.7314 120.824 25.7314 119.581V119.531Z"
          fill="white"
        />
        <path
          d="M62.9748 54L49.2185 40.25V30.4625C49.2185 29.6533 49.0089 28.8578 48.6102 28.1536C48.2116 27.4493 47.6374 26.8603 46.9435 26.4438L23.506 12.3813C22.6096 11.8439 21.5593 11.6215 20.522 11.7495C19.4847 11.8775 18.52 12.3484 17.781 13.0875L13.0935 17.775C12.3544 18.514 11.8834 19.4787 11.7555 20.516C11.6275 21.5533 11.8499 22.6036 12.3873 23.5L26.4498 46.9375C26.8663 47.6314 27.4553 48.2056 28.1595 48.6042C28.8638 49.0029 29.6592 49.2125 30.4685 49.2125H40.2435L53.131 62.1L62.9748 53.9938V54Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M78.4751 108.306L104.619 134.444C106.578 136.403 108.903 137.956 111.462 139.016C114.021 140.076 116.764 140.622 119.534 140.622C122.305 140.622 125.048 140.076 127.607 139.016C130.166 137.956 132.491 136.403 134.45 134.444C136.409 132.485 137.963 130.16 139.023 127.6C140.083 125.041 140.628 122.298 140.628 119.528C140.628 116.758 140.083 114.015 139.023 111.456C137.963 108.897 136.409 106.571 134.45 104.613L113.788 83.9563C110.62 84.4053 107.413 84.4955 104.225 84.2251C101.763 84.0126 99.9626 84.1875 98.8064 84.4875C98.4405 84.565 98.0885 84.6978 97.7626 84.8813L78.4751 108.306ZM99.8126 99.8063C100.692 98.9285 101.883 98.4354 103.125 98.4354C104.367 98.4354 105.559 98.9285 106.438 99.8063L118.156 111.531C118.617 111.96 118.986 112.478 119.242 113.053C119.499 113.628 119.636 114.249 119.648 114.878C119.659 115.507 119.543 116.133 119.307 116.716C119.071 117.3 118.72 117.83 118.275 118.275C117.83 118.72 117.3 119.071 116.716 119.307C116.133 119.543 115.507 119.659 114.878 119.647C114.249 119.636 113.628 119.499 113.053 119.242C112.478 118.986 111.96 118.617 111.531 118.156L99.8126 106.438C98.9348 105.559 98.4417 104.367 98.4417 103.125C98.4417 101.883 98.9348 100.691 99.8126 99.8126V99.8063Z"
          fill="white"
        />
      </svg>
      <div className="mx-auto text-center">
        <h4 className="font-bold text-white">
          Sorry, our website is currently under development, <br /> please try again later
        </h4>
      </div>
    </div>
  );
};

export default BlankView;
