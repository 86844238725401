import { useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const BlogsItem = () => {
  const [currentTab, setCurrentTab] = useState('all');

  const [itemsDisplay, setItemsDisplay] = useState([]);

  const tabs = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'News',
      value: 'News',
    },
    {
      label: 'Event',
      value: 'Event',
    },
    {
      label: 'Knowladge',
      value: 'Knowladge',
    },
  ];

  const blogList = [
    {
      releaseDate: '10 November 2023 .09:30 WIB',
      category: 'Knowladge',
      title: "Hacker 'Gentayangan' Bidik Perusahaan, Apa yang Mesti Dilakukan ?",
      shortDesc:
        'Perusahaan-perusahaan lintas sektor masih jadi sasaran peretesan dengan motif pemerasan atau ransomware. Ketimbang mengeluarkan dana besar usai dibobol, simak cara cegah yang lebih efektif.',
      imageUrl: 'assets/images/blogs_page/item_section/blog_1.png',
    },
    {
      releaseDate: '10 November 2023 .09:30 WIB',
      category: 'Knowladge',
      title: 'Menkominfo Respons Selebgram Beli Mercy Diduga Hasil Donasi',
      shortDesc: 'Menteri Komunikasi dan Informatika Budi Arie Setiadi mengungkap potensi pemutusan akses (takedown) dan kasus pidana buat donasi daring yang dilakukan selebgram Livy Renata',
      imageUrl: 'assets/images/blogs_page/item_section/blog_2.png',
    },
    {
      releaseDate: '10 November 2023 .09:30 WIB',
      category: 'Knowladge',
      title: 'Survei Meta: Anak Muda Main Medsos Tak Cuma Buat Senang-senang',
      shortDesc: 'Survei Meta mengungkap anak muda tak cuma memakai media sosial buat bersenang-senang, namun juga memakainya buat speak up terhadap isu-isu terkini',
      imageUrl: 'assets/images/blogs_page/item_section/blog_3.png',
    },
    {
      releaseDate: '10 November 2023 .09:30 WIB',
      category: 'Knowladge',
      title: 'Zuckerberg Turun Tangan Kirim Email ke Pegawai Google, Ada Apa?',
      shortDesc:
        'Saat ini, mereka yang punya keahlian di bidang kecerdasan buatan atau AI jadi buruan berbagai macam perusahaan, termasuk raksasa teknologi. Bahkan Mark Zuckerberg, pendiri Facebook dan CEO Meta, turun tangan langsung',
      imageUrl: 'assets/images/blogs_page/item_section/blog_4.png',
    },
    {
      releaseDate: '10 November 2023 .09:30 WIB',
      category: 'Knowladge',
      title: 'Elon Musk Bocorkan Harga Humanoid Optimus, Lebih Murah dari Tesla',
      shortDesc:
        'Dalam beberapa kesempatan Elon Musk memamerkan kemampuan robot humanoid Optimus yang akan diluncurkan dalam beberapa waktu mendatang. Kali ini, ia membocorkan harga robot mirip manusianya tersebut.',
      imageUrl: 'assets/images/blogs_page/item_section/blog_5.png',
    },
  ];

  useEffect(() => {
    const updateItemsDisplay = () => {
      if (currentTab !== 'all') {
        const newList = blogList.filter((blog) => blog.category === currentTab);
        setItemsDisplay(newList);
      } else {
        setItemsDisplay(blogList);
      }
    };

    updateItemsDisplay();
  }, [currentTab]);

  const handleChangeCurrentTab = useCallback((newTab) => {
    setCurrentTab(newTab);
  }, []);

  return (
    <section>
      <div className="flex items-center justify-start gap-10 overflow-x-scroll px-10 md:justify-center md:overflow-x-visible md:px-[50px] lg:px-[100px]">
        {tabs.map((tab, i) => (
          <button
            key={i}
            onClick={() => handleChangeCurrentTab(tab.value)}
            className={twMerge(
              'kanit-extralight border-b-4 border-transparent px-2 text-base text-white transition-all duration-300 md:text-[20px] lg:border-b-[6px] lg:text-[22px]',
              tab.value === currentTab && 'kanit-bold border-white text-lg leading-tight md:text-[22px] lg:text-[28px]'
            )}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="mt-[100px] flex flex-col gap-16 px-[50px] lg:px-[100px]">
        {itemsDisplay?.map((item, i) => (
          <div key={i} className="flex flex-col gap-7 lg:flex-row lg:gap-14">
            <div className="relative w-full rounded-2xl bg-gradient-to-b from-[#9A4BFF]/10 to-transparent p-5 ring-1 ring-[#9A4BFF]/50 lg:w-fit">
              <img src={item.imageUrl} alt={item.title} className="h-[286px] w-full object-cover object-center lg:w-[475px]" />
              <span className="absolute bottom-9 left-10 whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-5 py-[10px] font-montserrat text-sm font-medium leading-[150%] text-white shadow-lg shadow-secondary/30">
                Knowladge
              </span>
            </div>

            <div className="flex flex-1 flex-col gap-3">
              <p className="kanit-extralight text-white">{item.releaseDate}</p>
              <p className="kanit-bold text-3xl leading-normal text-white">{item.title}</p>
              <p className="kanit-light text-sm leading-loose text-white">{item.shortDesc}</p>

              <div className="">
                <button
                  aria-label="button-action"
                  className="kanit-bold whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-secondary/30"
                >
                  Read Now
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-28 flex items-center justify-center">
        <button
          aria-label="button-action"
          className="kanit-bold whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-secondary/30"
        >
          See More?
        </button>
      </div>
    </section>
  );
};

export default BlogsItem;
