import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// hooks
import { useResponsive } from '../../hooks/use-responsive';

const OurTeam = () => {
  const [swiperIndex, setSwiperIndex] = useState(0);

  const { smDown, lgUp } = useResponsive();

  const teamPhotos = [
    {
      instagram: 'https://www.linkedin.com/in/septio-dwi-91ab5b19a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
      image: '/assets/images/product_page/team/our-team-septio.png',
    },
    {
      instagram: 'https://www.instagram.com/rian.clone/',
      image: '/assets/images/product_page/team/our-team-rian.png',
    },
    {
      instagram: 'https://www.instagram.com/kalikaaa.kal',
      image: '/assets/images/product_page/team/our-team-ajeng.png',
    },
    {
      instagram: 'https://www.instagram.com/bay.uvsl',
      image: '/assets/images/product_page/team/our-team-bayu-maulana.png',
    },
    {
      instagram: 'https://www.instagram.com/rivaldiprtm01',
      image: '/assets/images/product_page/team/our-team-rivaldi-pratama.png',
    },
    {
      instagram: 'https://www.instagram.com/azrlfrdausa',
      image: '/assets/images/product_page/team/our-team-azril.png',
    },
  ];

  return (
    <section className="pt-[100px]">
      <div className="">
        <p className="kanit-bold text-center text-3xl text-white md:text-4xl">Easy on the journey team’s</p>
      </div>

      <div className="mt-[50px]">
        <Swiper
          slidesPerView={lgUp ? 3.5 : smDown ? 1 : 2}
          centeredSlides={true}
          pagination={{ clickable: true }}
          spaceBetween={lgUp ? 130 : smDown ? 10 : 90}
          loop={true}
          rewind={true}
          modules={[Pagination]}
          onActiveIndexChange={(swiper) => setSwiperIndex(swiper.realIndex)}
          className="mySwiper py-10"
        >
          {teamPhotos.map((dataTeam, i) => (
            <SwiperSlide key={i}>
              <div className={twMerge('flex h-[373px] w-full scale-90 items-center justify-center rounded-[42px] lg:w-[308px]', swiperIndex === i && 'md:scale-[1.1] lg:scale-[1.2]')}>
                <a href={dataTeam.instagram} className="h-full w-full">
                  <img src={dataTeam.image} alt={`team-${i + 1}`} loading="lazy" className="h-full w-full object-contain object-center" />
                </a>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default OurTeam;
