import React, { useEffect, useState } from 'react';
// components
import Layout from '../components/global/Layout';
import DocumentTitle from '../components/global/DocumentTitle';
import LoadingScreen from '../components/global/LoadingScreen';
//
import BlogsHero from '../components/blogs_page/BlogsHero';
import BlogsItem from '../components/blogs_page/BlogsItem';
import BlogsSubscription from '../components/blogs_page/BlogsSubscription';

const BlogsView = () => {
  const [loading, setLoading] = useState(true);

  DocumentTitle('Blogs');

  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 3000);
  // }, []);

  // if (loading) return <LoadingScreen />;

  return (
    <Layout>
      <BlogsHero />
      <BlogsItem />
      <BlogsSubscription />
    </Layout>
  );
};

export default BlogsView;
