import { useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const AcademyClass = () => {
  const [currentTab, setCurrentTab] = useState('All');

  const [projectDisplay, setProjectDisplay] = useState([]);

  function formatRupiah(angka) {
    let rupiah = '';
    const angkarev = angka.toString().split('').reverse().join('');
    for (let i = 0; i < angkarev.length; i++) if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + '.';
    return (
      'Rp. ' +
      rupiah
        .split('', rupiah.length - 1)
        .reverse()
        .join('')
    );
  }

  const tabs = [
    {
      label: 'All',
      value: 'All',
    },
    {
      label: 'Website Development',
      value: 'Web App',
    },
    {
      label: 'Mobile Development',
      value: 'Mobile App',
    },
    {
      label: 'Digital Marketing',
      value: 'Digital Marketing',
    },
  ];

  const projectList = [
    {
      name: 'Beginer Java Class : Spring boot ',
      category: 'Mobile App',
      price: 100000,
      imageUrl: 'assets/images/academy_page/springboot-class.png',
    },
    {
      name: 'Beginer MERN Class : Website ',
      category: 'Web App',
      price: 100000,
      imageUrl: 'assets/images/academy_page/mern-class.png',
    },
  ];

  useEffect(() => {
    const updateProjectDisplay = () => {
      if (currentTab !== 'All') {
        const newList = projectList.filter((prj) => prj.category === currentTab);
        setProjectDisplay(newList);
      } else {
        setProjectDisplay(projectList);
      }
    };

    updateProjectDisplay();
  }, [currentTab]);

  const handleChangeCurrentTab = useCallback((newTab) => {
    setCurrentTab(newTab);
  }, []);

  return (
    <section className="">
      <div className="flex items-center justify-start gap-10 overflow-x-scroll px-10 md:justify-center md:overflow-x-visible md:px-[50px] lg:px-[100px]">
        {tabs.map((tab, i) => (
          <button
            key={i}
            onClick={() => handleChangeCurrentTab(tab.value)}
            className={twMerge(
              'kanit-extralight border-b-4 border-transparent px-2 text-base text-white transition-all duration-300 md:text-[20px] lg:border-b-[6px] lg:text-[22px]',
              tab.value === currentTab && 'kanit-bold border-white text-lg leading-tight md:text-[22px] lg:text-[28px]'
            )}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="mt-[100px] grid grid-cols-1 gap-16 px-[50px] lg:grid-cols-2 lg:px-[100px]">
        {projectDisplay?.map((project, i) => (
          <div key={i} className="w-full rounded-2xl bg-gradient-to-b from-[#9A4BFF]/10 to-transparent p-5 ring-1 ring-[#9A4BFF]/50">
            <img src={project.imageUrl} alt={project.name} className="h-[250px] w-full rounded-2xl object-contain object-center md:h-[350px]" />

            <div className="mt-10 rounded-2xl bg-gradient-to-br from-primary to-secondary p-[1px] shadow-2xl shadow-secondary/40">
              <div className="flex flex-col items-center justify-between gap-3 rounded-2xl bg-[#231835] p-5 md:flex-row md:gap-0">
                <div className="flex flex-col items-center gap-2 md:items-start">
                  <p className="kanit-bold text-center text-[22px] text-white md:text-left lg:max-w-[100%]">{project.name}</p>
                  <p className="kanit-light text-sm text-white">{formatRupiah(project.price)}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AcademyClass;
