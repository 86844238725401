const Tech = () => {
  const techList = [
    '/assets/images/home_page/tech_section/react.svg',
    '/assets/images/home_page/tech_section/java.svg',
    '/assets/images/home_page/tech_section/flutter.svg',
    '/assets/images/home_page/tech_section/android_studio.svg',
    '/assets/images/home_page/tech_section/golang.svg',
    '/assets/images/home_page/tech_section/vue.svg',
    '/assets/images/home_page/tech_section/python.svg',
    '/assets/images/home_page/tech_section/js.svg',
    '/assets/images/home_page/tech_section/laravel.svg',
    '/assets/images/home_page/tech_section/php.svg',
    '/assets/images/home_page/tech_section/next-js.svg',
    '/assets/images/home_page/tech_section/nuxt-js.svg',
    '/assets/images/home_page/tech_section/nodejs.svg',
    '/assets/images/home_page/tech_section/figma.svg',
    '/assets/images/home_page/tech_section/ps.svg',
    '/assets/images/home_page/tech_section/ai.svg',
    '/assets/images/home_page/tech_section/pr.svg',
    '/assets/images/home_page/tech_section/ae.svg',
  ];

  return (
    <section id="tech" className="px-[50px] pt-[75px] lg:px-[100px] lg:pt-[160px]">
      <div className="">
        <p className="kanit-bold text-center text-3xl text-white md:text-4xl">Technology & Tools That We Use</p>
      </div>

      <div className="container m-auto mt-[50px] grid grid-cols-3 place-content-center gap-x-[20px] gap-y-[26px] lg:grid-cols-8 xl:grid-cols-9">
        {techList.map((tech, i) => (
          <div key={i} className="mx-auto flex h-[100px] w-[100px] flex-col items-center justify-center justify-self-center rounded-[26px] bg-gradient-to-b from-service-white to-service-purple">
            <img src={tech} alt={`tech-${i + 1}`} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Tech;
