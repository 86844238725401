import { twMerge } from 'tailwind-merge';

const MobileHero = () => {
  const images = [
    '/assets/images/service_page/mobile/hero_section/mobile_1.svg',
    '/assets/images/service_page/mobile/hero_section/mobile_2.svg',
    '/assets/images/service_page/mobile/hero_section/mobile_3.svg',
    '/assets/images/service_page/mobile/hero_section/mobile_4.svg',
    '/assets/images/service_page/mobile/hero_section/mobile_5.svg',
  ];

  const resizeImage = (imageIndex) => {
    let result = '';

    switch (imageIndex) {
      case 0:
        result = 'lg:h-[306px] lg:w-[137px]';
        break;
      case 1:
        result = 'lg:h-[361px] lg:w-[162px]';
        break;
      case 2:
        result = 'lg:h-[425px] lg:w-[192px]';
        break;
      case 3:
        result = 'lg:h-[361px] lg:w-[162px]';
        break;
      case 4:
        result = 'lg:h-[306px] lg:w-[137px]';
        break;
      default:
        result = 'lg:h-[306px] lg:w-[137px]';
        break;
    }

    return result;
  };

  return (
    <section className="mt-20 overflow-x-hidden px-5 pb-[75px] lg:pb-[100px]">
      <div className="flex flex-col items-center justify-center">
        <h1 className="kanit-bold mt-10 bg-gradient-to-b from-white to-white/20 bg-clip-text text-center text-3xl capitalize leading-[150%] text-transparent md:text-5xl lg:text-[62px]">
          <span className="mb-6 inline lg:mb-8 lg:block">Build your dream application</span>
          <span className="mx-2 md:mx-3 lg:mx-4">now with Exenesia</span>
        </h1>
      </div>

      <div className="relative mt-[90px] px-10 md:px-[50px] lg:mb-32 lg:px-[100px]">
        <div className="flex flex-row flex-wrap items-end justify-evenly gap-8 lg:justify-center">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt="web_preview"
              loading="lazy"
              className={twMerge('h-[425px] w-full rounded-md object-cover object-center drop-shadow-xl md:w-[192px] lg:z-10 lg:shadow-lg lg:shadow-white/30', resizeImage(index))}
            />
          ))}
        </div>

        <div className="absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]">
          <img src="/assets/images/service_page/mobile/hero_section/elllipse.svg" alt="ellipise" className="h-[525px] w-[525px] blur-sm" />
        </div>
      </div>
    </section>
  );
};

export default MobileHero;
