import { useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const PortfolioProjects = () => {
  const [currentTab, setCurrentTab] = useState('All');

  const [projectDisplay, setProjectDisplay] = useState([]);

  const tabs = [
    {
      label: 'All',
      value: 'All',
    },
    {
      label: 'Website Development',
      value: 'Web App',
    },
    {
      label: 'Mobile Development',
      value: 'Mobile App',
    },
    {
      label: 'Digital Marketing',
      value: 'Digital Marketing',
    },
  ];

  const projectList = [
    {
      name: 'Kartu AK-1 Digital Konut',
      url: 'kartu-ak-1-digital-konut',
      category: 'Mobile App',
      imageUrl: 'assets/images/portfolio_page/project_section/project_1.png',
    },
    {
      name: 'Silancar Konut',
      url: 'silancar-konut',
      category: 'Mobile App',
      imageUrl: 'assets/images/portfolio_page/project_section/project_2.png',
    },
    {
      name: 'Asistenku Apps',
      url: 'asistenku',
      category: 'Mobile App',
      imageUrl: 'assets/images/portfolio_page/project_section/project_3.png',
    },
    {
      name: 'KPI System Apps',
      url: 'hc-perfomance',
      category: 'Mobile App',
      imageUrl: 'assets/images/portfolio_page/project_section/project_4.png',
    },
    {
      name: 'Bionmed Apps',
      url: 'bonmed-apps',
      category: 'Mobile App',
      imageUrl: 'assets/images/portfolio_page/project_section/project_5.png',
    },
    {
      name: 'Eksam Apps',
      url: 'eksam-apps',
      category: 'Mobile App',
      imageUrl: 'assets/images/portfolio_page/project_section/project_6.png',
    },
    {
      name: 'Kean Signature',
      url: 'kea-signature',
      category: 'Web App',
      imageUrl: 'assets/images/portfolio_page/project_section/project_7.png',
    },
    {
      name: 'Nakertrans Konawe Utara',
      url: 'naketrans-konawe-utara',
      category: 'Web App',
      imageUrl: 'assets/images/portfolio_page/project_section/project_8.png',
    },
    {
      name: 'DLH Konawe Utara',
      url: 'naketrans-konawe-utara-dlh',
      category: 'Web App',
      imageUrl: 'assets/images/portfolio_page/project_section/project_9.png',
    },
    {
      name: 'Kartu AK-1 Web app',
      url: 'kartu-ak-1-digital-konut',
      category: 'Web App',
      imageUrl: 'assets/images/portfolio_page/project_section/project_10.png',
    },
    {
      name: 'Silancar Web App',
      url: 'silancar-konut',
      category: 'Web App',
      imageUrl: 'assets/images/portfolio_page/project_section/project_11.png',
    },
    {
      name: 'Halalin Web App',
      url: 'halal-in',
      category: 'Web App',
      imageUrl: 'assets/images/portfolio_page/project_section/project_12.png',
    },
    {
      name: 'Aerofood (procurement)',
      url: 'aerofood-acs',
      category: 'Web App',
      imageUrl: 'assets/images/portfolio_page/project_section/project_13.png',
    },
    {
      name: 'Listin (Community)',
      url: 'portfolio',
      category: 'Web App',
      imageUrl: 'assets/images/portfolio_page/project_section/project_14.png',
    },
  ];

  useEffect(() => {
    const updateProjectDisplay = () => {
      if (currentTab !== 'All') {
        const newList = projectList.filter((prj) => prj.category === currentTab);
        setProjectDisplay(newList);
      } else {
        setProjectDisplay(projectList);
      }
    };

    updateProjectDisplay();
  }, [currentTab]);

  const handleChangeCurrentTab = useCallback((newTab) => {
    setCurrentTab(newTab);
  }, []);

  return (
    <section className="">
      <div className="flex items-center justify-start gap-10 overflow-x-scroll px-10 md:justify-center md:overflow-x-visible md:px-[50px] lg:px-[100px]">
        {tabs.map((tab, i) => (
          <button
            key={i}
            onClick={() => handleChangeCurrentTab(tab.value)}
            className={twMerge(
              'kanit-extralight border-b-4 border-transparent px-2 text-base text-white transition-all duration-300 md:text-[20px] lg:border-b-[6px] lg:text-[22px]',
              tab.value === currentTab && 'kanit-bold border-white text-lg leading-tight md:text-[22px] lg:text-[28px]'
            )}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="mt-[100px] grid grid-cols-1 gap-16 px-[50px] lg:grid-cols-2 lg:px-[100px]">
        {projectDisplay?.map((project, i) => (
          <div key={i} className="w-full rounded-2xl bg-gradient-to-b from-[#9A4BFF]/10 to-transparent p-5 ring-1 ring-[#9A4BFF]/50">
            <img src={project.imageUrl} alt={project.name} className="h-[250px] w-full rounded-2xl object-contain object-center md:h-[350px]" />

            <div className="mt-10 rounded-2xl bg-gradient-to-br from-primary to-secondary p-[1px] shadow-2xl shadow-secondary/40">
              <div className="flex flex-col items-center justify-between gap-3 rounded-2xl bg-[#231835] p-5 md:flex-row md:gap-0">
                <div className="flex flex-col items-center gap-2 md:items-start">
                  <p className="kanit-bold text-center text-[22px] text-white md:text-left lg:max-w-[290px] lg:truncate">{project.name}</p>
                  <p className="kanit-light text-sm text-white">{project.category}</p>
                </div>
                {project.url !== 'portfolio' ? (
                  <a
                    className="kanit-bold whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-secondary/30"
                    href={`/portfolio-detail/${project.url}`}
                  >
                    Study Case
                  </a>
                ) : (
                  <button
                    aria-label="button-action"
                    className="kanit-bold whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-secondary/30"
                  >
                    Study Case
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PortfolioProjects;
