const OurProduct = () => {
  return (
    <section className="px-[50px] pt-[75px] lg:px-[100px] lg:pt-[160px]">
      <div className="">
        <p className="kanit-bold text-center text-3xl text-white md:text-4xl">Exenesia Our Product</p>
      </div>

      <div className="mt-[50px] flex flex-col items-center gap-5 lg:flex-row lg:items-start lg:gap-[44px]">
        <div className="w-[300px] md:h-[460px] md:w-[490px]">
          <img src="/assets/images/home_page/product_section/product-1.png" alt="product" className="h-full w-full" />
        </div>

        <div className="flex-1 pt-10">
          <p className="kanit-bold text-4xl text-[#D9D9D9] md:text-5xl">
            Achieve achievements and <br /> increase your sales with us at{' '}
          </p>
          <p className="kanit-light mt-6 leading-loose text-[#B0B0B0]">
            Platform for searching for raw materials, which are intended to help entrepreneurs <br /> in meeting their production needs. with good quality and cheap prices
          </p>

          <div className="relative mt-12 flex flex-col items-center justify-center md:flex-row">
            <div className="left-0 top-[50%] flex translate-y-[-50%] flex-row items-center justify-center gap-4 md:absolute">
              <img src="/assets/images/home_page/product_section/logo-product-1.png" alt="logo-product" className="h-[50px] w-[50px]" />

              <p className="font-montserrat text-white">
                <span className="block font-bold">Bikin</span>
                <span>Usaha</span>
              </p>
            </div>

            <button
              aria-label="button-action"
              className="kanit-bold whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-secondary/30"
            >
              Visit Now
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurProduct;
