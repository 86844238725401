const AboutSubscription = () => {
  return (
    <section className="mt-[150px] rounded-tl-[100px] rounded-tr-[100px] bg-gradient-to-b from-client-bg-white to-client-bg-lighter-white px-[50px] py-20 pb-[75px] lg:rounded-tl-[200px] lg:rounded-tr-[200px] lg:px-[100px] lg:pb-[60px]">
      <div className="flex flex-col items-center justify-around lg:flex-row">
        <div className="col-span-8 flex flex-col items-start justify-start">
          <h3 className="kanit-bold max-w-[445px] text-left text-[36px] capitalize leading-[150%] text-keeps-us-going-white">Start now to explore your business ideas with us</h3>
          <p className="kanit-light mb-[26px] mt-4 max-w-[530px] text-base leading-[150%] text-[#B0B0B0]">do it now before your idea is preceded by competitors</p>

          <div className="mb-[35px] flex w-full flex-col items-center justify-between gap-3 border-[0.5px] border-subscription-border bg-service-btn-bg py-2 pl-4 pr-2 text-sm leading-[150%] text-[#A2A2A2] md:w-[430px] md:flex-row md:gap-0">
            <div className="flex flex-row items-center gap-2">
              <img src="assets/images/about_page/subscription_section/whatsapp.png" alt="whatsapp" className="h-6 w-6" />

              <p className="kanit-light text-white">+628175141004</p>
            </div>

            <a
              href="https://wa.me/+628175141004"
              target="_blank"
              rel="noopener noreferrer"
              className="kanit-bold whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-secondary/30"
            >
              Contact Me
            </a>
          </div>
        </div>

        <img src="/assets/images/home_page/subscription_section/thumbnail.svg" alt="" />
      </div>
    </section>
  );
};

export default AboutSubscription;
