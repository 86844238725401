const AboutHero = () => {
  return (
    <section id="hero" className="mt-20 pb-[75px] lg:pb-[98px]">
      <div className="flex flex-col items-center justify-center">
        <h1 className="kanit-bold mt-10 bg-gradient-to-b from-white to-white/20 bg-clip-text text-center text-3xl capitalize leading-[150%] text-transparent md:text-5xl lg:text-[62px]">
          <span className="mb-6 inline md:block lg:mb-8">working together with a cohesive</span>
          <span className="mx-2 md:mx-3 lg:mx-4">team is an achievement</span>
        </h1>

        <div className="mt-[50px] flex flex-row flex-wrap items-center justify-center gap-x-[25px]">
          <div className="flex items-center justify-start gap-x-[7px]">
            <img src="/assets/images/global/checklist.svg" alt="checklist.svg" />
            <div className="flex items-center">
              <h5 className="kanit-regular text-left text-xs capitalize leading-[150%] text-purple-light">Projects:</h5>
              <span className="kanit-bold text-2xl text-purple-light">&ensp; 55+</span>
            </div>
          </div>

          <div className="flex items-center justify-start gap-x-[7px]">
            <img src="/assets/images/global/checklist.svg" alt="checklist.svg" />
            <div className="flex items-center">
              <h5 className="kanit-regular text-left text-xs capitalize leading-[150%] text-purple-light">Clients:</h5>
              <span className="kanit-bold text-2xl text-purple-light">&ensp; 76+</span>
            </div>
          </div>

          <div className="flex items-center justify-start gap-x-[7px]">
            <img src="/assets/images/global/checklist.svg" alt="checklist.svg" />
            <div className="flex items-center">
              <h5 className="kanit-regular text-left text-xs capitalize leading-[150%] text-purple-light">Partners:</h5>
              <span className="kanit-bold text-2xl text-purple-light">&ensp; 11+</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHero;
