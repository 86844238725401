import React from 'react';
// components
import Layout from '../../components/global/Layout';
import DocumentTitle from '../../components/global/DocumentTitle';
//
import WebHero from '../../components/service_page/web/WebHero';
import WebBusiness from '../../components/service_page/web/WebBusiness';
import WebPortfolio from '../../components/service_page/web/WebPortfolio';
import WebSubscription from '../../components/service_page/web/WebSubscription';

const ServiceWebView = () => {
  DocumentTitle('Service - Web Development');

  return (
    <Layout>
      <WebHero />
      <WebBusiness />
      <WebPortfolio />
      <WebSubscription />
    </Layout>
  );
};

export default ServiceWebView;
