import React, { useEffect, useState } from 'react';
// components
import Layout from '../components/global/Layout';
import DocumentTitle from '../components/global/DocumentTitle';
// import LoadingScreen from '../components/global/LoadingScreen';
//
import PortfolioHero from '../components/portfolio_page/PortfolioHero';
import PortfolioProjects from '../components/portfolio_page/PortfolioProjects';
import PortfolioSubscription from '../components/portfolio_page/PortfolioSubscription';

const PortfolioView = () => {
  // const [loading, setLoading] = useState(true);

  DocumentTitle('Portofolio');

  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 3000);
  // }, []);

  // if (loading) return <LoadingScreen />;

  return (
    <Layout>
      <PortfolioHero />
      <PortfolioProjects />
      <PortfolioSubscription />
    </Layout>
  );
};

export default PortfolioView;
