const VisionMission = () => {
  const imageVisionMission = '/assets/images/product_page/vision-mision-event.png';
  const logoEasyOnTheJourney = '/assets/images/product_page/logo-easy-on-the-journey.png';
  const bgHero = '/assets/images/product_page/bg-product.png';

  return (
    <section className="pt-[75px] lg:pt-[160px]">
      <div className="grid grid-cols-1 gap-10 px-10 md:gap-10 md:px-[50px] lg:grid-cols-2 lg:px-[100px]">
        <div className="flex items-center justify-center">
          <img src={imageVisionMission} alt="vision-mision-event.png" className="h-full w-full object-contain object-center md:h-[382px] md:w-[493px]" />
        </div>

        <div>
          <div className="mb-5 md:mb-10">
            <div className="flex h-[60px] items-center justify-between rounded-[16px] bg-cover p-3" style={{ backgroundImage: `url(${bgHero})` }}>
              <h3 className="text-[26px] font-bold text-[#000]">Our Vision</h3>
              <img src={logoEasyOnTheJourney} className="w-[70px]" alt="logo-easy-on-the-journey.png" />
            </div>
            <div className="p-5">
              <p className="text-[14p] text-white">
                Navigasi untuk memulai peta kehidupan agar tidak tersesat dan berani menempuh rintangan melampui sesuatu eksperiens yang tidak pernah mereka bayangkan 
              </p>
            </div>
          </div>

          <div className="">
            <div className="flex h-[60px] items-center justify-between rounded-[16px] bg-cover p-3" style={{ backgroundImage: `url(${bgHero})` }}>
              <h3 className="text-[26px] font-bold text-[#000]">Our Vision</h3>
              <img src={logoEasyOnTheJourney} className="w-[70px]" alt="logo-easy-on-the-journey.png" />
            </div>
            <div className="p-5">
              <p className="text-[14p] text-white">
                Navigasi untuk memulai peta kehidupan agar tidak tersesat dan berani menempuh rintangan melampui sesuatu eksperiens yang tidak pernah mereka bayangkan 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VisionMission;
