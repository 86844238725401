import { useRef, useEffect, useState, useCallback } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

const ContactMain = () => {
  const [dialogState, setDialogState] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    criteriaMode: 'all',
  });

  const watchs = watch();

  // ~ CHANGE NULL TO UNDEFINED ~
  useEffect(() => {
    if (!watchs.demoDate?.startDate) {
      setValue('demoDate', undefined);
    }
  }, [watchs.demoDate?.startDate]);

  const handleClickSubmit = useCallback((data) => {
    try {
      const resultData = {
        ...data,
        demoDate: data.demoDate?.startDate,
      };

      setDialogState(true);
      reset();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const closeDialog = useCallback(() => {
    setDialogState(false);
  }, []);

  const controllers = [
    {
      name: 'fullname',
      rules: { required: 'Fullname is required!' },
    },
    {
      name: 'whatsappNumber',
      rules: { required: 'Whatsapp Number is required!', pattern: { value: /^[0-9]+$/, message: 'Invalid phone number!' }, minLength: { value: 10, message: 'Must not be less than 10 digits!' } },
    },
    {
      name: 'email',
      rules: { required: 'Email is required!' },
    },
    {
      name: 'companyName',
      rules: { required: 'Company name is required!' },
    },
    {
      name: 'demoDate',
      rules: { required: 'Date is required!' },
    },
    {
      name: 'userMessage',
      rules: {},
    },
  ];

  return (
    <section className="mt-20 flex flex-col gap-10 px-[50px] pb-5 md:pb-[50px] lg:flex-row lg:px-[100px]">
      <div className="flex flex-col gap-5 lg:w-[45%]">
        <p className="text-center font-montserrat text-[26px] font-black text-white md:text-left">EXENESIA DIGITAL SOLUTION</p>
        <p className="text-center font-montserrat text-sm leading-loose text-white md:text-left">
          Perumahan Citoh Cluster Krakatau No.Blok D24, Cibatok 1, Kec. Cibungbulang, Kabupaten Bogor, Jawa Barat 16630
        </p>

        <div className="flex flex-col items-center gap-2 md:flex-row md:gap-10">
          <p className="kanit-bold text-sm text-white">+62 89533-1001-831</p>
          <p className="kanit-bold text-sm text-white">exenesia.com</p>
          <p className="kanit-bold text-sm text-white">exenesia@gmail.com</p>
        </div>

        <div className="mt-10">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.5424777569165!2d106.66154976896253!3d-6.579274849733046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69db0017934e49%3A0x822cbd4fc5b9adf0!2sPERUMAHAN%20CITOH!5e0!3m2!1sid!2sid!4v1716073418838!5m2!1sid!2sid"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="h-[350px] w-full rounded-2xl border-none"
          ></iframe>
        </div>
      </div>

      <div className="lg:w-[55%]">
        <div className="rounded-2xl bg-gradient-to-b from-[#9A4BFF] to-secondary p-[1px]">
          <div className="rounded-2xl bg-[#231835] px-3 py-5 md:px-16 md:py-8">
            <p className="text-center font-montserrat text-[26px] font-black text-white md:text-left">Digitalize with Exenesia now!</p>
            <p className="mt-3 text-center font-montserrat text-sm text-white md:text-left">We will be open and helpful in your business operations</p>

            <form onSubmit={handleSubmit(handleClickSubmit)} className="mt-5 flex w-full flex-col justify-center gap-5">
              {controllers.map((controller, index) => (
                <Controller
                  key={index}
                  control={control}
                  name={controller.name}
                  rules={controller.rules}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      {controller.name === 'fullname' && (
                        <input
                          onChange={onChange}
                          type="text"
                          placeholder="Fullname"
                          value={value}
                          name={controller.name}
                          className="w-full bg-[#D3D3D3]/20 p-3 font-mulish text-sm text-white placeholder:text-white"
                        />
                      )}

                      {controller.name === 'whatsappNumber' && (
                        <input
                          onChange={onChange}
                          type="text"
                          placeholder="Whatsapp Number"
                          value={value}
                          name={controller.name}
                          className="w-full bg-[#D3D3D3]/20 p-3 font-mulish text-sm text-white placeholder:text-white"
                        />
                      )}

                      {controller.name === 'email' && (
                        <input
                          onChange={onChange}
                          type="email"
                          placeholder="Email"
                          value={value}
                          name={controller.name}
                          className="w-full bg-[#D3D3D3]/20 p-3 font-mulish text-sm text-white placeholder:text-white"
                        />
                      )}

                      {controller.name === 'companyName' && (
                        <input
                          onChange={onChange}
                          type="text"
                          placeholder="Company Name"
                          value={value}
                          name={controller.name}
                          className="w-full bg-[#D3D3D3]/20 p-3 font-mulish text-sm text-white placeholder:text-white"
                        />
                      )}

                      {controller.name === 'demoDate' && (
                        <Datepicker
                          useRange={false}
                          asSingle={true}
                          value={value}
                          onChange={onChange}
                          placeholder="Select a demo date"
                          inputClassName="font-mulish w-full bg-[#D3D3D3]/20 p-3 text-sm text-white placeholder:text-white"
                        />
                      )}

                      {controller.name === 'userMessage' && (
                        <textarea
                          onChange={onChange}
                          placeholder="Fill in your message here (Optional)"
                          value={value}
                          name={controller.name}
                          rows={4}
                          className="w-full bg-[#D3D3D3]/20 p-3 font-mulish text-sm text-white placeholder:text-white"
                        />
                      )}

                      <ErrorMessage
                        errors={errors}
                        name={controller.name}
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <p key={type} className="text-sm font-medium text-error">
                              {message}
                            </p>
                          ))
                        }
                      />
                    </div>
                  )}
                />
              ))}

              <button
                type="submit"
                disabled={isSubmitting}
                className="kanit-bold whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-5 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-neutral-800/70"
              >
                Send Request
              </button>
            </form>
          </div>
        </div>
      </div>

      <ModalDialog state={dialogState} closeDialog={() => closeDialog()} />
    </section>
  );
};

function ModalDialog({ state, closeDialog }) {
  const dialogRef = useRef(null);

  const modalLogoPath = '/assets/images/contact_page/main_section/xbot.svg';

  // ~ OPEN MODAL DIALOG ~
  useEffect(() => {
    if (state) {
      dialogRef?.current?.showModal();
    }
  }, [state]);

  return (
    <dialog ref={dialogRef} className="modal modal-bottom sm:modal-middle">
      <div className="modal-box flex flex-col items-center justify-center gap-4 !rounded-none">
        <div className="flex items-center justify-center">
          <img src={modalLogoPath} alt="xbot" className="h-[128px] w-[100px]" />
        </div>

        <div className="flex flex-col items-center">
          <p className="font-montserrat text-[26px] font-black">Send Submission Succes</p>
          <p className="font-montserrat text-sm">Thank you for contacting us, we will contact you soon</p>
        </div>

        <div className="modal-action">
          <form method="dialog">
            <button
              onClick={closeDialog}
              className="kanit-bold whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-neutral-800/70"
            >
              Done
            </button>
          </form>
        </div>
      </div>
    </dialog>
  );
}

export default ContactMain;
