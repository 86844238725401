const AboutHistory = () => {
  const histories = [
    {
      year: 2020,
      title: 'Starting for journey',
      desc: 'We started the process of helping companies digitalize their business',
    },
    {
      year: 2022,
      title: 'Go digital era',
      desc: 'Starting to walk in developing the Go Digital business',
    },
    {
      year: 2024,
      title: 'Go asia',
      desc: 'Running to be able to start or compete in the digital era in Asia in helping the rulers',
    },
  ];

  return (
    <section className="flex flex-col items-center justify-center rounded-tl-[100px] rounded-tr-[100px] bg-gradient-to-b from-client-bg-white to-client-bg-lighter-white px-[50px] py-20 lg:rounded-tl-[200px] lg:rounded-tr-[200px] lg:px-0">
      <p className="kanit-bold leading-auto text-center text-3xl capitalize tracking-[1px] text-[#D9D9D9] md:text-4xl">How the history of Exenesia was formed</p>

      <div className="mt-[50px] flex max-w-lg flex-col items-center justify-between gap-10 lg:max-w-6xl lg:flex-row lg:items-start lg:gap-[56px]">
        {histories.map((history, i) => (
          <div key={i} className="flex basis-1/3 flex-col items-center justify-center gap-3">
            <div className="relative">
              <div className="flex h-[110px] w-[110px] items-center justify-center rounded-full bg-gradient-to-br from-transparent to-[#160B29] drop-shadow-lg">
                <span className="kanit-bold text-3xl text-[#D9D9D9]">{history.year}</span>
              </div>

              {i !== histories.length - 1 && (
                <img src="assets/images/about_page/history_section/dashed.png" alt="dashed.png" className="absolute left-[160px] top-[50px] hidden min-w-[200px] max-w-[200px] lg:block" />
              )}
            </div>

            <p className="kanit-bold text-2xl text-white">{history.title}</p>
            <p className="kanit-light text-center text-[#B0B0B0]">{history.desc}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AboutHistory;
