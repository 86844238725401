import { useEffect } from 'react';

const PortfolioHero = () => {
  const clientList = [
    'assets/images/portfolio_page/hero_section/client_1.png',
    'assets/images/portfolio_page/hero_section/client_2.png',
    'assets/images/portfolio_page/hero_section/client_3.png',
    'assets/images/portfolio_page/hero_section/client_4.png',
    'assets/images/portfolio_page/hero_section/client_5.png',
    'assets/images/portfolio_page/hero_section/client_6.png',
    'assets/images/portfolio_page/hero_section/client_7.png',
    'assets/images/portfolio_page/hero_section/client_8.png',
    'assets/images/portfolio_page/hero_section/client_9.png',
    'assets/images/portfolio_page/hero_section/client_10.png',
    'assets/images/portfolio_page/hero_section/client_11.png',
    'assets/images/portfolio_page/hero_section/client_12.png',
    'assets/images/portfolio_page/hero_section/client_13.png',
    'assets/images/portfolio_page/hero_section/client_14.png',
  ];

  return (
    <section className="mt-20 overflow-x-hidden px-5 pb-[75px] lg:pb-[100px]">
      <div className="flex flex-col items-center justify-center">
        <h1 className="kanit-bold mt-10 bg-gradient-to-b from-white to-white/20 bg-clip-text text-center text-3xl capitalize leading-[150%] text-transparent md:text-5xl lg:text-[62px]">
          <span className="mb-6 inline lg:mb-8 lg:block">our creativity to help you handle</span>
          <span className="mx-2 md:mx-3 lg:mx-4">business digitalization</span>
        </h1>

        <div className="mt-[50px]">
          <AutoScroller
            direction="left"
            cards={
              <>
                {clientList.map((client, i) => (
                  <div key={i} className="via-whte/70 h-[70px] w-[200px] rounded-2xl bg-gradient-to-b from-white/15 to-[#160B29]/30 p-3 drop-shadow-md">
                    <img src={client} alt={`client-${i + 1}`} className="h-full w-full object-contain object-center" />
                  </div>
                ))}
              </>
            }
          />
        </div>
      </div>
    </section>
  );
};

function AutoScroller({ cards, direction }) {
  useEffect(() => {
    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      addAnimation();
    }
  }, []);

  const addAnimation = () => {
    const scrollers = document.querySelectorAll('.scroller');
    scrollers.forEach((scroller) => {
      scroller.setAttribute('data-animated', true);
      const scrollerInner = scroller.querySelector('.scroller__inner');
      const scrollerContent = Array.from(scrollerInner.children);
      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        duplicatedItem.setAttribute('aria-hidden', true);
        scrollerInner.appendChild(duplicatedItem);
      });
    });
  };

  return (
    <div className="scroller" data-direction={direction} data-speed="slow">
      <div className="scroller__inner">{cards}</div>
    </div>
  );
}

export default PortfolioHero;
