import AutoScroller from './AutoScroller';

const Hero = () => {
  const clientList = [
    'assets/images/home_page/hero_section/client_1.png',
    'assets/images/home_page/hero_section/client_2.png',
    'assets/images/home_page/hero_section/client_3.png',
    'assets/images/home_page/hero_section/client_4.png',
    'assets/images/home_page/hero_section/client_5.png',
    'assets/images/home_page/hero_section/client_6.png',
    'assets/images/home_page/hero_section/client_7.png',
    'assets/images/home_page/hero_section/client_8.png',
    'assets/images/home_page/hero_section/client_9.png',
    'assets/images/home_page/hero_section/client_10.png',
    'assets/images/home_page/hero_section/client_11.png',
    'assets/images/home_page/hero_section/client_12.png',
    'assets/images/home_page/hero_section/client_13.png',
    'assets/images/home_page/hero_section/client_14.png',
  ];

  return (
    <section id="hero" className="mt-5 overflow-x-hidden pb-[75px] lg:pb-[98px]">
      <div className="flex flex-col items-center justify-center px-[50px] lg:px-[100px]">
        <div className="kanit-regular flex w-[350px] items-center justify-center bg-gradient-to-r from-white/0 via-white/70 to-white/0 py-1">
          <h3 className="kanit-regular z-[1] bg-gradient-to-r from-secondary to-primary bg-clip-text text-base leading-[150%] text-transparent">Technology 5.0</h3>
        </div>

        <h1 className="kanit-bold mt-20 bg-gradient-to-b from-white to-white/20 bg-clip-text text-center text-3xl capitalize leading-[150%] text-transparent md:text-5xl lg:text-[62px]">
          <span className="mb-6 inline md:block lg:mb-8">build your business line with</span>
          <span className="mx-2 md:mx-3 lg:mx-4">digitalization for company progress</span>
        </h1>

        <p className="kanit-light mb-[28px] mt-[30px] text-center text-2xl capitalize leading-[150%] text-purple-light">“ Success is not an end in itself, but a means to a greater goal “</p>

        <div className="mt-[50px]">
          <AutoScroller
            direction="left"
            cards={
              <>
                {clientList.map((client, i) => (
                  <div key={i} className="via-whte/70 h-[70px] w-[200px] rounded-2xl bg-gradient-to-b from-white/15 to-[#160B29]/30 p-3 drop-shadow-md">
                    <img src={client} alt={`client-${i + 1}`} className="h-full w-full object-contain object-center" />
                  </div>
                ))}
              </>
            }
          />
        </div>

        <div className="mt-10 flex items-center justify-center gap-x-[25px]">
          <div className="flex items-center justify-start gap-x-[7px]">
            <img src="/assets/images/global/checklist.svg" alt="checklist" />
            <span className="kanit-regular text-left text-xs capitalize leading-[150%] text-purple-light">Trusted by many clients</span>
          </div>
          <div className="flex items-center justify-start gap-x-[7px]">
            <img src="/assets/images/global/checklist.svg" alt="checklist" />
            <span className="kanit-regular text-left text-xs capitalize leading-[150%] text-purple-light">100+ Completed Projects</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
