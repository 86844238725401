const BannerHero = () => {
  const imageHero = '/assets/images/product_page/easy-on-the-journey.png';
  const bgHero = '/assets/images/product_page/bg-product.png';

  return (
    <section className="">
      <div className="kanit mt-[50px] grid grid-cols-1 gap-16 px-10 md:px-[50px] lg:grid-cols-1 lg:px-[100px]">
        <div className="w-full rounded-2xl bg-gradient-to-b from-[#9A4BFF]/10 to-transparent p-5 ring-1 ring-[#9A4BFF]/50">
          <div className="rounded-3xl bg-gradient-to-br from-primary to-secondary p-[1px] shadow-2xl shadow-secondary/40">
            <div
              className="flex flex-col-reverse items-center justify-center gap-3 rounded-2xl bg-[#231835] bg-cover bg-center p-[10px] md:gap-3 md:p-[50px] lg:grid-cols-1 lg:flex-row lg:justify-between"
              style={{ backgroundImage: `url(${bgHero})` }}
            >
              <div className="">
                <h3 className="kanit-bold mb-3 bg-gradient-to-r from-[#7622C7] via-[#EC5D81] to-[#F69605] bg-clip-text text-center text-4xl font-bold text-transparent md:text-[45px] lg:mb-8 lg:text-left">
                  Easy on the journey
                </h3>
                <p className="kanit mb-8 text-center text-[14px] leading-[220%] text-[#000000] lg:w-[416px] lg:text-left">
                  Easy on the Journey adalah Program tentang IT dan Digital Marketing yang mengajarkan cara menghadapi rintangan dengan mudah. Kami akan tunjukkan bahwa perjalanan tidak semenyulit
                  yang dibayangkan dengan memberikan solusi praktis
                </p>

                <div className="flex flex-col justify-center gap-3 md:flex-row">
                  <div className="flex flex-row items-center rounded-[14px] bg-[#E8E9D1] p-3">
                    <img src="/assets/images/product_page/logo/logo-exenesia.png" alt="logo-product" className="h-[30px] w-[30px]" />
                    <p className="mx-3 font-montserrat">
                      <span className="kanit block text-[16px] font-extrabold text-[#000]">Exenesia</span>
                    </p>
                  </div>
                  <div className="flex flex-row items-center rounded-[14px] bg-[#E8E9D1] p-3">
                    <img src="/assets/images/product_page/logo/logo-fan.png" alt="logo-product" className="h-[30px] w-[30px]" />
                    <p className="mx-3 font-montserrat">
                      <span className="kanit block text-[16px] font-extrabold text-[#000]">FAUST AEGIS</span>
                      <span className="kanit block font-bold text-[#000]">NETWORK</span>
                    </p>
                  </div>
                  <div className="flex flex-row items-center rounded-[14px] bg-[#E8E9D1] p-3">
                    <img src="/assets/images/product_page/logo/logo-begis.png" alt="logo-product" className="h-[30px] w-[30px]" />
                    <p className="mx-3 font-montserrat">
                      <span className="kanit block text-[16px] font-extrabold text-[#000]">Begis</span>
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <img src={imageHero} alt={imageHero} className="w-full object-contain object-center md:h-[324px] lg:w-[421px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerHero;
