import React from 'react';
// components
import Layout from '../../components/global/Layout';
import DocumentTitle from '../../components/global/DocumentTitle';
//
import MobileHero from '../../components/service_page/mobile/MobileHero';
import MobileBusiness from '../../components/service_page/mobile/MobileBusiness';
import MobilePortfolio from '../../components/service_page/mobile/MobilePortfolio';
import MobileSubscription from '../../components/service_page/mobile/MobileSubscription';

const ServiceMobileView = () => {
  DocumentTitle('Service - Specialist Mobile');

  return (
    <Layout>
      <MobileHero />
      <MobileBusiness />
      <MobilePortfolio />
      <MobileSubscription />
    </Layout>
  );
};

export default ServiceMobileView;
