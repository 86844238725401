import { useEffect } from 'react';
import { useState } from 'react';
// components
import DocumentTitle from '../components/global/DocumentTitle';
import Layout from '../components/global/Layout';
import LoadingScreen from '../components/global/LoadingScreen';
//
import Hero from '../components/home_page/Hero';
import Tech from '../components/home_page/Tech';
import Solution from '../components/home_page/Solution';
import OurProduct from '../components/home_page/OurProduct';
import OurPartner from '../components/home_page/OurPartner';
import Subscription from '../components/home_page/Subscription';
import OurPortfolio from '../components/home_page/OurPortfolio';
import WhatKeepsUsGoing from '../components/home_page/WhatKeepsUsGoing';

function HomeView() {
  DocumentTitle('Home');

  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 3000);
  // }, []);

  // if (loading) return <LoadingScreen />;

  return (
    <Layout>
      <Hero />
      <WhatKeepsUsGoing />
      <Solution />
      <Tech />
      <OurPortfolio />
      <OurProduct />
      <OurPartner />
      <Subscription />
    </Layout>
  );
}

export default HomeView;
