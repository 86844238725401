const IdentityApps = (props) => {
  const portfolioData = props.data;
  // const title = props.param;
  return (
    <section className="px-10 pt-12 md:px-[50px] lg:px-[100px] lg:pt-[160px]">
      <div className="flex flex-col">
        <div className="w-fulls flex flex-col lg:flex-row lg:gap-14">
          <div className="relative grid w-full grid-cols-1 gap-5 rounded-2xl bg-gradient-to-b from-[#9A4BFF]/10 to-transparent p-5 ring-1 ring-[#9A4BFF]/50 lg:grid-cols-3">
            <div className="w-full lg:col-span-1">
              <ul className="space-y-4">
                <li className="grid grid-cols-1 items-start gap-1 py-2 text-white md:grid-cols-3 md:py-4">
                  <h5 className="kanit-bold col-span-1 text-[18px] lg:text-[20px]">Client</h5>
                  <p className="col-span-2 text-[18px] lg:text-[20px]">{portfolioData.client}</p>
                </li>
                <li className="grid grid-cols-1 items-start gap-1 py-2 text-white md:grid-cols-3 md:py-4">
                  <h5 className="kanit-bold col-span-1 text-[18px] lg:text-[20px]">Brand</h5>
                  <p className="col-span-2 text-[18px] lg:text-[20px]">{portfolioData.brand}</p>
                </li>
                <li className="grid grid-cols-1 items-start gap-1 py-2 text-white md:grid-cols-3 md:py-4">
                  <h5 className="kanit-bold col-span-1 text-[18px] lg:text-[20px]">Our Role</h5>
                  <p className="col-span-2 text-[18px] lg:text-[20px]">{portfolioData.our_role}</p>
                </li>
              </ul>
            </div>
            <div className="lg:col-span-2">
              <p className="kanit-normal text-[18px] leading-relaxed text-white lg:text-[20px]">{portfolioData.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IdentityApps;
