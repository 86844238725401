const WhatKeepsUsGoing = () => {
  const vmgList = [
    {
      logoUrl: '/assets/images/home_page/keeps_us_going_section/star.png',
      title: 'Our Mission',
      desc: 'Becoming a solution for MSME business actors or other businesses who want to jointly build business development through digital platforms, especially in Indonesia',
    },
    {
      logoUrl: '/assets/images/home_page/keeps_us_going_section/fire.png',
      title: 'Our Vission',
      desc: 'We want to create an economy through digitalization to make transactions easier for sellers and buyers and with others',
    },
    {
      logoUrl: '/assets/images/home_page/keeps_us_going_section/lightning.png',
      title: 'Our Goals',
      desc: 'Help expand the reach of MSMEs and other businesses to reach customers / buyers / clients who can help and realize the economy',
    },
  ];

  return (
    <section
      id="what_keeps_us_going"
      className="flex flex-col items-center justify-center rounded-tl-[100px] rounded-tr-[100px] bg-gradient-to-b from-client-bg-white to-client-bg-lighter-white px-[50px] pt-20 lg:rounded-tl-[200px] lg:rounded-tr-[200px] lg:px-0"
    >
      <div className="">
        <p className="kanit-bold text-center text-3xl text-white md:text-4xl">What Keeps Us Going</p>
      </div>

      <div className="container mt-[50px] grid grid-cols-1 gap-[74px] px-[50px] lg:grid-cols-3 lg:px-[100px]">
        {vmgList.map((vmg, i) => (
          <div key={i} className="flex flex-col items-center">
            <img src={vmg.logoUrl} alt={vmg.title} />

            <h4 className="kanit-bold leading-auto mb-[3px] mt-[25px] text-center text-[22px] capitalize tracking-[1px] text-[#D9D9D9]">{vmg.title}</h4>
            <p className="kanit-light text-center text-base leading-[180%] text-[#B0B0B0]">{vmg.desc}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhatKeepsUsGoing;
