const GalleryPhoto = () => {
  const gallery = [
    {
      name: '',
      image: '/assets/images/product_page/gallery/galeri-1.png',
    },
    {
      name: '',
      image: '/assets/images/product_page/gallery/galeri-2.png',
    },
    {
      name: '',
      image: '/assets/images/product_page/gallery/galeri-3.png',
    },
    {
      name: '',
      image: '/assets/images/product_page/gallery/galeri-4.png',
    },
    {
      name: '',
      image: '/assets/images/product_page/gallery/galeri-5.png',
    },
    {
      name: '',
      image: '/assets/images/product_page/gallery/galeri-6.png',
    },
  ];

  return (
    <section className="">
      <div className="kanit grid grid-cols-1 gap-16 px-10 pt-[75px] md:px-[50px] lg:grid-cols-2 lg:px-[100px] lg:pt-[75px]">
        {gallery.map((galleries, i) => (
          <div key={i} className="w-full rounded-2xl bg-gradient-to-b">
            <div className="rounded-3xl shadow-2xl">
              <img src={galleries.image} alt={galleries.image} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default GalleryPhoto;
