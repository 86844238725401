const WebBusiness = () => {
  return (
    <section className="w-full rounded-tl-[100px] rounded-tr-[100px] bg-gradient-to-b from-client-bg-white via-client-bg-lighter-white to-client-bg-lighter-white px-10 pt-20 md:px-[50px] lg:rounded-tl-[200px] lg:rounded-tr-[200px] lg:px-[100px]">
      <div className="flex w-full flex-col items-center justify-between gap-20 lg:flex-row lg:gap-0">
        <div className="flex max-w-md flex-1 flex-col items-center justify-center lg:items-start">
          <div className="flex flex-col gap-6">
            <p className="kanit-bold text-3xl capitalize text-[#D9D9D9] md:text-5xl">Web Development</p>
            <p className="kanit-bold text-3xl capitalize text-[#D9D9D9] md:text-5xl">for your business</p>
          </div>

          <p className="kanit-light mt-7 text-center text-base leading-loose text-white lg:text-left">
            We can help you digitalize your company. by building a system that can help operations, and can increase your sales
          </p>

          <button
            aria-label="button-action"
            className="kanit-bold mt-9 whitespace-nowrap bg-gradient-to-r from-secondary to-primary px-10 py-[10px] text-base leading-[150%] text-grey-secondary shadow-lg shadow-secondary/30"
          >
            Contact Us
          </button>
        </div>

        <div className="">
          <img src="/assets/images/service_page/web/business_section/tech_service_web.svg" alt="xbot_services" loading="lazy" className="h-[423px] w-[492px]" />
        </div>
      </div>

      <div className="mt-5 flex flex-col items-center justify-between gap-10 lg:gap-5 xl:flex-row">
        <div className="rounded-2xl bg-gradient-to-br from-[#9A4BFF] via-[#9A4BFF]/40 to-transparent p-[1px]">
          <div className="flex flex-col gap-7 rounded-2xl bg-[#160B29] bg-gradient-to-b from-white/10 via-[#160B29]/30 to-[#160B29]/30 p-10 drop-shadow-md md:w-[666px] xl:w-[530px]">
            <p className="kanit-bold text-2xl text-[#D9D9D9] md:text-3xl">Customize Web App</p>
            <p className="kanit-light text-base text-white">Services in creating web apps according to your business operational needs: ERP System and others</p>
          </div>
        </div>

        <div className="rounded-2xl bg-gradient-to-br from-[#9A4BFF] via-[#9A4BFF]/40 to-transparent p-[1px]">
          <div className="flex flex-col gap-7 rounded-2xl bg-[#160B29] bg-gradient-to-b from-white/10 via-[#160B29]/30 to-[#160B29]/30 p-10 drop-shadow-md md:w-[666px] xl:w-[530px]">
            <p className="kanit-bold text-2xl text-[#D9D9D9] md:text-3xl">Website App Development</p>
            <p className="kanit-light text-base text-white">Services for creating websites to help scale up your company. with advanced technology</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebBusiness;
