import React from 'react';

const AcademyHero = () => {
  return (
    <section className="mt-20 overflow-x-hidden px-[50px] pb-[75px] lg:px-[100px] lg:pb-[100px]">
      <div className="flex flex-col items-center justify-center">
        <h1 className="kanit-bold mt-10 bg-gradient-to-b from-white to-white/20 bg-clip-text text-center text-3xl capitalize leading-[150%] text-transparent md:text-5xl lg:text-[62px]">
          Develop your skills and abilities <br /> with exedemy in the digital world
        </h1>
      </div>
    </section>
  );
};

export default AcademyHero;
